import React from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';

const Avatar = ({ src, alt, active, className }) => (
  <div
    className={classNames('avatar', { 'avatar--active': active }, className)}
  >
    {src && <img src={src} alt={alt} className="avatar__img" />}
  </div>
);

Avatar.defaultProps = {
  alt: 'User avatar'
};

Avatar.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  active: PropTypes.bool,
  className: PropTypes.string
};

export default Avatar;

import React, { forwardRef } from 'react';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Input from '@components/shared/Form/Input';
import Select from '@components/shared/Form/Select/SimpleSelect';
import CreatableSelect from '@components/shared/Form/Select/CreatableSelect';
import Textarea from '@components/shared/Form/Textarea';
import RadioButton from '@components/shared/Form/Radio';
import Rating from '@components/shared/Rating';
import Error from '@components/shared/Error';

const fieldTypes = {
  checkbox: 'checkbox',
  switch: 'switch',
  radio: 'radio',
  select: 'select',
  textarea: 'textarea',
  text: 'text',
  number: 'number',
  email: 'email',
  password: 'password',
  rating: 'rating'
};

// eslint-disable-next-line react/display-name
const FormikField = forwardRef(
  (
    {
      type,
      name,
      label,
      helpText,
      className,
      isOptional,
      isCreatable,
      showErrors,
      ...props
    },
    ref
  ) => {
    const renderFormField = () => {
      switch (type) {
        case fieldTypes.select:
          if (isCreatable) {
            return CreatableSelect;
          }
          return Select;
        case fieldTypes.textarea:
          return Textarea;
        case fieldTypes.radio:
          return RadioButton;
        case fieldTypes.rating:
          return Rating;
        default:
          return Input;
      }
    };

    return (
      <Field name={name} {...props}>
        {({ field, meta: { error, touched }, form: { setFieldTouched } }) => {
          const Component = renderFormField();
          const hasError = touched && error;

          if (type === fieldTypes.text && !('autoComplete' in props)) {
            props.autoComplete = 'off';
          }

          return (
            <div
              className={classNames(
                'form-field',
                { 'form-field--error': hasError },
                className
              )}
            >
              {label && type !== fieldTypes.radio && (
                <label htmlFor={name} className="form-field__label">
                  {label} {isOptional && <span>Optional</span>}
                </label>
              )}
              <Component
                type={type}
                ref={ref}
                {...props}
                {...field}
                label={label}
                value={field.value}
                error={hasError && error}
                onBlur={e => {
                  // eslint-disable-next-line react/prop-types
                  props.onBlur && props.onBlur(e);
                  field.onBlur(e);
                }}
                onChange={e => {
                  // eslint-disable-next-line react/prop-types
                  props.onChange && props.onChange(e);
                  field.onChange(e);
                  setTimeout(() => {
                    !touched && setFieldTouched(name);
                  }, 0);
                }}
              />
              {helpText && !hasError && (
                <p className="form-field__help">{helpText}</p>
              )}
              {showErrors && hasError && (
                <Error text={error} className="form-field__error" />
              )}
            </div>
          );
        }}
      </Field>
    );
  }
);

FormikField.defaultProps = {
  options: [],
  isOptional: false,
  showErrors: true
};

FormikField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array,
  helpText: PropTypes.string,
  className: PropTypes.string,
  isOptional: PropTypes.bool,
  isCreatable: PropTypes.bool,
  type: PropTypes.oneOf(Object.values(fieldTypes)),
  showErrors: PropTypes.bool
};

export default FormikField;

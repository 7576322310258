import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Select, { components } from 'react-select';

import Icon from '@components/shared/Icon';

const DropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <Icon icon="chevron-down" />
  </components.DropdownIndicator>
);

const BubbleSelect = ({ bold, color, options, className, ...props }) => {
  const mainClass = 'bubble-select';

  return (
    <Select
      options={options}
      components={{ DropdownIndicator }}
      isSearchable={false}
      classNamePrefix={mainClass}
      className={classNames(mainClass, className, {
        [`${mainClass}--bold`]: bold,
        [`${mainClass}--${color}`]: color // blue, yellow
      })}
      {...props}
    />
  );
};

BubbleSelect.defaultProps = {
  bold: false,
  color: null
};

BubbleSelect.propTypes = {
  bold: PropTypes.bool,
  color: PropTypes.string,
  options: PropTypes.array,
  className: PropTypes.string
};

export default BubbleSelect;

import React from 'react';
import { Form, Formik } from 'formik';
import classnames from 'classnames';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import { AddCureDescriptionSchema } from './schema';

import {
  setData,
  setStep,
  setReviewOption,
  submitForm
} from '@store/user-review/actions';
import { reviewFlows, reviewSteps } from '@constants/review';
import { apiUrls } from '@constants/api';
import EditFormSelect from '@components/Profile/EditForm/EditFormSelect';
import Title from '@components/shared/Title';
import Button from '@components/shared/Button';
import FormikField from '@components/shared/FormikField';
import Error from '@components/shared/Error';
import FileInput from '@components/shared/Form/File';
import Icon from '@components/shared/Icon';
import Link from '@components/shared/Link';
import Stepper from '@components/shared/Stepper';
import RatingWithLabels from '@components/LeaveReview/RatingWithLabels/RatingWithLabels';
import Logger from '@utils/Logger';
import Helpers from '@utils/Helpers';
import { ratingCaptions } from '@constants/common';

const AddDescriptionForm = ({
  setData,
  setStep,
  data,
  reviewIsSkippable,
  user,
  setReviewOption,
  isRecipeReview,
  stepper,
  submitForm
}) => {
  const submitReview = (values, actions) => {
    actions.setSubmitting(true);
    try {
      submitForm({
        onFailed: err => {
          actions.setErrors(err);
          actions.setSubmitting(false);
        }
      });
    } catch (e) {
      actions.setSubmitting(false);
      Logger.error(e);
    }
  };

  const handleSubmit = async (values, actions) => {
    try {
      setData(values);
      if (isRecipeReview) {
        submitReview(values, actions);
        return;
      }

      if (values.score <= 3) {
        setStep(reviewSteps.recommendations);
      } else {
        setStep(reviewSteps.additionalInfo);
      }
    } catch (err) {
      actions.setErrors(err);
    }
  };

  const handleSkip = () => {
    setReviewOption({ flowType: reviewFlows.product });
    if (user) {
      if (isRecipeReview) {
        setStep(reviewSteps.successImmediate);
      } else {
        setStep(reviewSteps.success);
      }
    } else {
      setStep(reviewSteps.guestBanner);
    }
  };

  let formTitle = 'How well did it work for you?';
  let ratingLowCaption = ratingCaptions.common.low;
  let ratingHighCaption = ratingCaptions.common.high;

  if (isRecipeReview) {
    formTitle = 'Would you recommend this recipe?';
    ratingLowCaption = ratingCaptions.recipe.low;
    ratingHighCaption = ratingCaptions.recipe.high;
  }

  return (
    <Formik
      enableReinitialize
      validateOnMount
      validationSchema={AddCureDescriptionSchema}
      initialValues={{
        body: data.body,
        photos: data.photos,
        score: data.score,
        title: data.title,
        modifications: data.modifications
      }}
      onSubmit={handleSubmit}
    >
      {({ values, touched, isSubmitting, errors, setFieldValue }) => (
        <Form
          className="leave-review__form"
          onKeyDown={Helpers.preventKeyEvent}
        >
          <Title className="leave-review__form-title" size="md">
            {formTitle}
          </Title>
          <Error text={errors.non_field_errors} nonField={true} />
          <RatingWithLabels
            ratingHighCaption={ratingHighCaption}
            ratingLowCaption={ratingLowCaption}
            name="score"
          />
          {touched.score && <Error text={errors.score} nonField={true} />}
          <FormikField
            name="title"
            type="text"
            placeholder="Title of your review"
          />
          <FormikField
            name="body"
            type="textarea"
            placeholder="Here's where you write your main review. The more details, the more helpful it'll be!"
            rows={4}
          />
          {isRecipeReview && (
            <EditFormSelect
              label="Modifications"
              type="select"
              name="modifications"
              isOptional
              instanceId="modifications"
              getOptionLabel={option => option}
              getOptionValue={option => option}
              getNewOptionData={(value, label) => label}
              hideControls
              helpText="Ex. Used almond flour instead of regular flour"
            />
          )}
          <FileInput
            initialValue={values.photos}
            className="leave-review__files"
            uploadUrl={apiUrls.files.reviewsPhotos}
            placeholder={
              <>
                <div className="leave-review__files-title">
                  <div className="row ai-middle-xxs jc-center-xxs">
                    Add photos <Icon icon="plus-slim" />
                  </div>
                </div>
                <div>Up to 5 photos, max 3mb</div>
              </>
            }
            multiple
            onChange={files => setFieldValue('photos', files)}
            showPreviews
            maxSize={3}
          />
          <div className="leave-review__form-footer">
            <div
              className={classnames('row no-margin ai-middle-xxs', {
                'jc-between-xxs': !isRecipeReview,
                'jc-end-xxs': isRecipeReview
              })}
            >
              {!isRecipeReview && (
                <Stepper steps={stepper.steps} current={stepper.current} />
              )}
              <div>
                {reviewIsSkippable && (
                  <Link
                    className="leave-review__back-btn"
                    bold
                    type="button"
                    onClick={handleSkip}
                  >
                    Skip
                  </Link>
                )}
                <Button disabled={isSubmitting} type="submit" color="yellow">
                  Next
                </Button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

AddDescriptionForm.propTypes = {
  isRecipeReview: PropTypes.bool,
  user: PropTypes.any,
  data: PropTypes.object,
  setData: PropTypes.func,
  setStep: PropTypes.func,
  submitForm: PropTypes.func,
  setReviewOption: PropTypes.func,
  reviewIsSkippable: PropTypes.bool,
  stepper: PropTypes.any
};

const mapStateToProps = ({
  auth: { user },
  userReview: { data, reviewIsSkippable, stepper }
}) => ({
  user,
  data,
  reviewIsSkippable,
  stepper
});

export default connect(mapStateToProps, {
  setData,
  setStep,
  setReviewOption,
  submitForm
})(AddDescriptionForm);

import { takeEvery, put } from 'redux-saga/effects';
import Router from 'next/router';

import { clearUser, setUser } from './actions';
import types from './types';

import API from '@utils/API';
import { apiUrls } from '@constants/api';
import Logger from '@utils/Logger';
import { confirmWrapper } from '@components/shared/AsyncConfirm';
import routes from '@constants/routes';

function* asyncLogout({ payload: { onSuccess } }) {
  const answer = yield confirmWrapper('Are you sure that you want to logout?', {
    acceptText: 'Yes, logout',
    declineText: 'No, stay logged in',
    inverted: true
  });

  if (!answer) {
    return;
  }

  try {
    yield API.post(apiUrls.auth.logout);
    yield put(clearUser());
    onSuccess && onSuccess();
    Router.push(routes.home);
  } catch (e) {
    Logger.error(e);
  }
}

function* asyncFetchShortProfile() {
  try {
    const res = yield API.get(apiUrls.profile.short);
    yield put(setUser(res.data));
  } catch (e) {
    Logger.error(e);
  }
}

const withPrefix = action => `${types.PREFIX}/${action}`;

export const authSagas = [
  takeEvery(withPrefix(types.LOGOUT), asyncLogout),
  takeEvery(withPrefix(types.GET_SHORT_PROFILE), asyncFetchShortProfile)
];

export default {
  PREFIX: 'REVIEW',

  LEAVE_REVIEW: 'LEAVE_REVIEW',
  LEAVE_REVIEW_SUCCESS: 'LEAVE_REVIEW_SUCCESS',

  SET_STEP: 'SET_STEP',
  SET_DATA: 'SET_DATA',
  SET_LAST_REVIEW: 'SET_LAST_REVIEW',

  RESET_FLOW: 'RESET_FLOW',

  TOGGLE_MODAL: 'TOGGLE_MODAL',

  SUBMIT_FORM: 'SUBMIT_FORM',

  SET_REVIEW_OPTION: 'SET_REVIEW_OPTION',

  UPDATE_STEPPER: 'UPDATE_STEPPER',

  UPDATE_SUCCESS_BANNER: 'UPDATE_SUCCESS_BANNER',
  UPDATE_GUEST_BANNER: 'UPDATE_GUEST_BANNER',

  REMOVE_REVIEW: 'REMOVE_REVIEW',
  PATCH_REVIEW: 'PATCH_REVIEW',
  CLAIM_DATA: 'CLAIM_DATA'
};

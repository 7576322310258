import React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Link from '@components/shared/Link';

const FooterNavItem = ({ label, onClick, as, href }) => (
  <Link href={href} as={as || href} onClick={onClick}>
    {label}
  </Link>
);

FooterNavItem.propTypes = {
  label: PropTypes.string.isRequired,
  href: PropTypes.string,
  as: PropTypes.string,
  onClick: PropTypes.func
};

const FooterList = ({ user, list, className }) => (
  <ul className={`footer-list ${className}`}>
    {list
      .filter(i => (i.protected ? user : true))
      .map(item => (
        <li key={item.label} className="footer-list__item">
          {FooterNavItem(item)}
        </li>
      ))}
  </ul>
);

FooterList.propTypes = {
  list: PropTypes.array,
  className: PropTypes.string,
  user: PropTypes.any
};

const mapStateToProps = ({ auth: { user } }) => ({
  user
});

export default connect(mapStateToProps)(FooterList);

import { handleActions } from 'redux-actions';

import types from './types';

const initialState = {
  conditionsList: [],
  currentCondition: null
};

const reducer = {
  [types.SET_CONDITIONS]: (state, { payload }) => ({
    ...state,
    conditionsList: payload
  }),
  [types.SET_CURRENT_CONDITION]: (state, { payload }) => ({
    ...state,
    currentCondition: state.conditionsList.find(
      condition => condition.slug === payload
    )
  }),
  [types.CLEAR_CURRENT_CONDITION]: state => ({
    ...state,
    currentCondition: null
  })
};

export const conditionsReducer = handleActions(reducer, initialState, {
  prefix: types.PREFIX
});

export default {
  PREFIX: 'COMMENTS',

  FETCH_COMMENTS: 'FETCH_COMMENTS',
  FETCH_COMMENTS_SUCCESS: 'FETCH_COMMENTS_SUCCESS',
  FETCH_COMMENTS_FAILED: 'FETCH_COMMENTS_FAILED',

  CHANGE_URLS: 'CHANGE_URLS',
  RESET_URLS: 'RESET_URLS',

  CHANGE_PAGINATION: 'CHANGE_PAGINATION',
  RESET_PAGINATION: 'RESET_PAGINATION'
};

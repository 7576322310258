import React, { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import get from 'lodash/get';
import { connect } from 'react-redux';

import { logout } from '@store/auth/actions';
import {
  setCurrentCondition,
  clearCurrentCondition
} from '@store/conditions/actions';
import { toggleModal as toggleReviewModal } from '@store/user-review/actions';
import MobileHeader from '@components/shared/Header/MobileHeader';
import DesktopHeader from '@components/shared/Header/DesktopHeader';
import Icon from '@components/shared/Icon';

const Header = ({
  route,
  setCurrentCondition,
  clearCurrentCondition,
  ...props
}) => {
  useEffect(() => {
    if (route.query.condition) {
      setCurrentCondition(route.query.condition);
    } else {
      clearCurrentCondition();
    }
  }, [route]);

  const userId = get(props, 'user.id');

  const conditionOptions = [
    ...props.conditionsList,
    {
      id: null,
      name: (
        <strong className="condition--contact-us">
          <Icon icon="plus-slim" />
          <span>Suggest Condition</span>
        </strong>
      )
    }
  ];

  return (
    <header key={userId} className="header">
      <MobileHeader
        {...props}
        conditionOptions={conditionOptions}
        route={route}
      />
      <DesktopHeader
        {...props}
        conditionOptions={conditionOptions}
        route={route}
      />
    </header>
  );
};

Header.defaultProps = {
  headerConfig: {
    visibility: {
      createAccount: true,
      conditions: true,
      signUp: false,
      signIn: true,
      reviewButton: true
    }
  },
  conditionsList: []
};

Header.propTypes = {
  headerConfig: PropTypes.object,
  route: PropTypes.object,
  setCurrentCondition: PropTypes.func,
  clearCurrentCondition: PropTypes.func,
  toggleReviewModal: PropTypes.func,
  conditionsList: PropTypes.array
};

const mapStateToProps = ({
  auth: { user },
  conditions: { currentCondition, conditionsList }
}) => ({
  user,
  conditionsList,
  currentCondition
});

export default connect(mapStateToProps, {
  toggleReviewModal,
  setCurrentCondition,
  clearCurrentCondition,
  logout
})(Header);

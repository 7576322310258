import Cookies from '@utils/Cookies';

const maxAgeHours = 24;

/*
  It can be more simple, with project requirements, hope that I will refactor this later
*/
class GuestReviews {
  static reviewCookieKey = 'cur-guest-review';
  static productCookieKey = 'cur-guest-product';
  static recipeCookieKey = 'cur-guest-recipe';
  static successStoryCookieKey = 'cur-guest-story';

  static set(key, id) {
    Cookies.set(key, id, {
      maxAge: maxAgeHours * 60 * 60,
      path: '/'
    });
  }

  static setProduct(productId) {
    this.set(this.productCookieKey, productId);
  }

  static getProduct() {
    return Cookies.get(this.productCookieKey);
  }

  static removeProduct() {
    Cookies.remove(this.productCookieKey);
  }

  static setRecipe(recipeId) {
    this.set(this.recipeCookieKey, recipeId);
  }

  static getRecipe() {
    return Cookies.get(this.recipeCookieKey);
  }

  static removeRecipe() {
    Cookies.remove(this.recipeCookieKey);
  }

  static setReview(reviewObj) {
    this.set(this.reviewCookieKey, JSON.stringify(reviewObj));
  }

  static getReview() {
    const data = Cookies.get(this.reviewCookieKey);
    return data ? JSON.parse(data) : null;
  }

  static removeReview() {
    Cookies.remove(this.reviewCookieKey);
  }

  static setSuccessStory(storyId) {
    this.set(this.successStoryCookieKey, storyId);
  }

  static getSuccessStory() {
    return Cookies.get(this.successStoryCookieKey);
  }

  static removeSuccessStory() {
    Cookies.remove(this.successStoryCookieKey);
  }
}

export default GuestReviews;

import * as Yup from 'yup';

export const AddCureDescriptionSchema = Yup.object().shape({
  title: Yup.string()
    .label('Title')
    .required(),
  body: Yup.string()
    .label('Body')
    .nullable(),
  score: Yup.number()
    .label('Rating')
    .min(1, 'Please leave a star rating')
    .required(),
  modifications: Yup.array()
    .label('Modifications')
    .max(20)
});

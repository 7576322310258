import React from 'react';
import classnames from 'classnames';
import * as PropTypes from 'prop-types';

const RadioButton = ({
  label,
  value,
  radioValue,
  className,
  onChange,
  // eslint-disable-next-line
  error,
  ...props
}) => {
  const wrapperClassName = classnames('form-radio', className);
  return (
    <div className={wrapperClassName}>
      <label className="form-radio__wrapper">
        {label && label}
        <input
          className="form-radio__input"
          type="radio"
          checked={value === radioValue}
          onChange={() =>
            onChange({
              target: {
                name: props.name,
                value: radioValue
              }
            })
          }
          {...props}
        />
        <span className="form-radio__mark" />
      </label>
    </div>
  );
};

RadioButton.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  radioValue: PropTypes.any.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.any
};

export default RadioButton;

import { takeEvery, put } from 'redux-saga/effects';

import types from './types';

import { fetchDietsSuccess, fetchSymptomsSuccess } from '@store/common/actions';
import API from '@utils/API';
import { apiUrls } from '@constants/api';
import Logger from '@utils/Logger';

function* asyncFetchDiets() {
  try {
    const { data } = yield API.get(apiUrls.common.diets);
    yield put(fetchDietsSuccess(data));
  } catch (err) {
    Logger.error(err);
  }
}

function* asyncFetchSymptoms({ payload }) {
  try {
    const { data } = yield API.get(apiUrls.common.symptoms, payload);
    yield put(fetchSymptomsSuccess(data));
  } catch (err) {
    Logger.error(err);
  }
}

const withPrefix = action => `${types.PREFIX}/${action}`;

export const commonSagas = [
  takeEvery(withPrefix(types.FETCH_DIETS), asyncFetchDiets),
  takeEvery(withPrefix(types.FETCH_SYMPTOMS), asyncFetchSymptoms)
];

import React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  setData,
  setStep,
  updateSuccessBanner
} from '@store/user-review/actions';
import API from '@utils/API';
import GuestReviews from '@utils/GuestReviews';
import { reviewSteps } from '@constants/review';
import { apiUrls } from '@constants/api';
import routes from '@constants/routes';
import RecipeForm from '@components/Cures/RecipeForm/RecipeForm';

const AddRecipeForm = ({
  user,
  data,
  setData,
  setStep,
  updateSuccessBanner
}) => {
  const handleSubmit = async (values, actions) => {
    actions.setSubmitting(true);
    try {
      const { data } = await API.post(apiUrls.cures.createRecipe, values);

      setData({
        cure: data.slug,
        cure_title: values.name,
        isNewCure: true
      });

      if (!user) {
        GuestReviews.setRecipe(data.slug);
        setStep(reviewSteps.guestBanner);
      } else {
        updateSuccessBanner({
          redirectUri: {
            href: routes.cures.details.pattern,
            as: routes.cures.details.path(data.slug)
          }
        });
        setStep(reviewSteps.successImmediate);
      }
    } catch (err) {
      actions.setErrors(err);
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <RecipeForm
      onSubmit={handleSubmit}
      initialValues={data}
      classNamePrefix="leave-review__form"
      formTitle="Adding a recipe to CureRate"
    />
  );
};

AddRecipeForm.propTypes = {
  user: PropTypes.any,
  data: PropTypes.object,
  setData: PropTypes.func,
  setStep: PropTypes.func,
  updateSuccessBanner: PropTypes.func
};

const mapStateToProps = ({ auth: { user }, userReview: { data } }) => ({
  user,
  data
});

export default connect(mapStateToProps, {
  setData,
  setStep,
  updateSuccessBanner
})(AddRecipeForm);

import Compressor from 'compressorjs';

function compressFile(file) {
  return new Promise((res, rej) => {
    new Compressor(file, {
      quality: 0.6,
      maxWidth: 500,
      maxHeight: 500,
      success(result) {
        res(result);
      },
      error(err) {
        rej(err);
      }
    });
  });
}

export default compressFile;

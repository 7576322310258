export default {
  PREFIX: 'FAVORITES',

  SET_FILTERS: 'SET_FILTERS',
  RESET_FILTERS: 'RESET_FILTERS',

  FETCH_LIST: 'FETCH_LIST',
  FETCH_LIST_SUCCESS: 'FETCH_LIST_SUCCESS',

  RESET_DATA: 'RESET_DATA'
};

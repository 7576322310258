import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Error from '@components/shared/Error';
import Thumbs from '@components/shared/Form/File/Thumbs';
import helpers from '@utils/Helpers';
import compressFile from '@utils/ImgCompressor';
import API from '@utils/API';
import Logger from '@utils/Logger';

const FileInput = ({
  uploadUrl,
  accept,
  multiple,
  maxSize,
  showPreviews,
  onChange,
  maxAmount,
  placeholder,
  className,
  initialValue
}) => {
  const [files, setFiles] = useState(initialValue || []);
  const [error, setError] = useState(null);
  const { getRootProps, getInputProps } = useDropzone({
    accept,
    multiple,
    maxSize: helpers.mbToBytes(maxSize),
    onDrop: acceptedFiles => {
      const totalCounter = acceptedFiles.length + files.length;
      if (maxAmount && totalCounter > maxAmount) {
        setError(`You can't select more than ${maxAmount} files`);
        return;
      }
      setError(null);
      handleUpload(acceptedFiles);
    },
    onDropRejected() {
      setError(`You can't upload files larger than ${maxSize}MB`);
    }
  });

  const handleRemove = file => {
    setFiles(files.filter(f => f.id !== file.id));
  };

  useEffect(() => {
    if (!files.length && Array.isArray(initialValue)) {
      setFiles(initialValue);
    }
  }, [initialValue]);

  useEffect(() => {
    onChange(files);
  }, [files]);

  const handleUpload = async filesForUpload => {
    try {
      const compressed = await Promise.all(filesForUpload.map(compressFile));
      const formData = new FormData();
      compressed.forEach(file => {
        formData.append('files', file, file.name);
      });
      const { data } = await API.post(uploadUrl, formData);
      setFiles([...files, ...data]);
    } catch (e) {
      Logger.error(e);
    }
  };

  return (
    <section className={classNames('form-file', className)}>
      <div {...getRootProps({ className: 'form-file__dropzone' })}>
        <input {...getInputProps()} />
        <div>{placeholder}</div>
      </div>
      {showPreviews && <Thumbs list={files} onRemove={handleRemove} />}
      {error && <Error nonField text={error} />}
    </section>
  );
};

FileInput.defaultProps = {
  accept: 'image/*',
  multiple: false,
  showPreviews: false,
  placeholder: 'Drag n drop some files here, or click to select files',
  maxSize: 1,
  maxAmount: 5,
  onChange: () => void 0
};

FileInput.propTypes = {
  uploadUrl: PropTypes.string.isRequired,
  className: PropTypes.string,
  accept: PropTypes.string,
  multiple: PropTypes.bool,
  showPreviews: PropTypes.bool,
  maxSize: PropTypes.number,
  onChange: PropTypes.func,
  maxAmount: PropTypes.number,
  initialValue: PropTypes.array,
  placeholder: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
};

export default FileInput;

import React, { useState, useRef } from 'react';
import { Form, Formik } from 'formik';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import { SelectCureFormSchema } from './schema';

import { apiUrls } from '@constants/api';
import { cureTypes } from '@constants/common';
import {
  addNewOption,
  customOptionCreator,
  formatOption
} from '@components/LeaveReview/utils';
import { reviewFlows, reviewSteps } from '@constants/review';
import { setData, setReviewOption, setStep } from '@store/user-review/actions';
import Error from '@components/shared/Error';
import Title from '@components/shared/Title';
import Button from '@components/shared/Button';
import FormikField from '@components/shared/FormikField';
import Autocomplete from '@components/shared/Autocomplete';
import API from '@utils/API';
import Logger from '@utils/Logger';
import Stepper from '@components/shared/Stepper';
import Helpers from '@utils/Helpers';

const SelectCureForm = ({
  conditionsList,
  currentCondition,
  setStep,
  setData,
  setReviewOption,
  stepper
}) => {
  const [initialOptions, setInitialOptions] = useState([addNewOption]);
  const autocompleteRef = useRef();

  const handleAddNewClick = () => {
    setStep(reviewSteps.addCure);
  };

  const CustomOption = customOptionCreator(handleAddNewClick);

  const handleLoadOptions = async (inputText, callback, condition) => {
    try {
      const { data } = await API.get(apiUrls.cures.search, {
        disease_condition: condition.slug,
        search: inputText
      });

      const formattedData = [
        {
          label: 'Supplements',
          options: data[`${cureTypes.supplement}s`].map(formatOption)
        },
        {
          label: 'Products',
          options: data[`${cureTypes.product}s`].map(formatOption)
        },
        addNewOption
      ].filter(o => o.options.length);

      setInitialOptions(formattedData);
      callback(formattedData);
    } catch (err) {
      Logger.error(err);
    }
  };

  const handleSubmit = values => {
    setData({
      cure: values.cure.value,
      cure_title: values.cure.label,
      type: values.cure.type,
      condition: values.condition
    });
    setReviewOption({ flowType: reviewFlows.review });
    setStep(reviewSteps.addDescription);
  };

  return (
    <Formik
      enableReinitialize
      validateOnMount
      initialValues={{
        condition: currentCondition || conditionsList[0],
        cure: ''
      }}
      validationSchema={SelectCureFormSchema}
      onSubmit={handleSubmit}
    >
      {({ values, isValid, isSubmitting, setFieldValue, errors, touched }) => (
        <Form
          className="leave-review__form leave-review__form--large"
          onKeyDown={Helpers.preventKeyEvent}
        >
          <Title className="leave-review__form-title" size="md">
            Review something you&apos;ve tried
          </Title>
          <FormikField
            name="condition"
            type="select"
            instanceId="condition"
            options={conditionsList}
            isClearable={false}
            visibleIndicators
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            onChange={value => {
              setFieldValue('condition', value);
              setFieldValue('cure', '');
              setInitialOptions([addNewOption]);
              if (autocompleteRef.current) {
                autocompleteRef.current.clearInputText();
              }
            }}
            label="Your condition:"
          />
          <div className="form-field">
            <label htmlFor="cures-search" className="form-field__label">
              What you tried:
            </label>
            <Autocomplete
              ref={autocompleteRef}
              className="leave-review__autocomplete"
              showSearchIcon={true}
              showSearchButton={false}
              options={initialOptions}
              instanceId="cures-search"
              color="primary"
              loadOptions={(text, cb) =>
                handleLoadOptions(text, cb, values.condition)
              }
              value={values.cure}
              onChange={value => setFieldValue('cure', value)}
              components={{ Option: CustomOption }}
              placeholder="Search and select"
            />
            {touched.cure && errors.cure && (
              <Error text={errors.cure} className="form-field__error" />
            )}
          </div>
          <div className="leave-review__form-footer">
            <div className="row no-margin ai-middle-xxs jc-between-xxs">
              <Stepper current={stepper.current} steps={stepper.steps} />
              <Button
                disabled={!isValid || isSubmitting}
                type="submit"
                color="yellow"
              >
                Next
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

SelectCureForm.propTypes = {
  data: PropTypes.object,
  setData: PropTypes.func,
  setStep: PropTypes.func,
  setReviewOption: PropTypes.func,
  conditionsList: PropTypes.array,
  currentCondition: PropTypes.any,
  stepper: PropTypes.any
};

const mapStateToProps = ({
  userReview: { data, stepper },
  conditions: { conditionsList, currentCondition }
}) => ({
  data,
  conditionsList,
  currentCondition,
  stepper
});

export default connect(mapStateToProps, { setData, setStep, setReviewOption })(
  SelectCureForm
);

import { createActions } from 'redux-actions';

import types from './types';

const identityActions = [
  types.SET_FILTERS,
  types.RESET_FILTERS,
  types.FETCH_LIST,
  types.FETCH_LIST_SUCCESS,
  types.MARK_AS_REVIEWED
];

export const {
  setFilters,
  resetFilters,
  fetchList,
  fetchListSuccess,
  markAsReviewed
} = createActions({}, ...identityActions, {
  prefix: types.PREFIX
});

import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { connect } from 'react-redux';
import get from 'lodash/get';
import * as PropTypes from 'prop-types';

import { fetchList, toggleModal } from '@store/report/actions';
import API from '@utils/API';
import Logger from '@utils/Logger';
import Modal from '@components/shared/Modal';
import Title from '@components/shared/Title';
import Button from '@components/shared/Button';
import FormikField from '@components/shared/FormikField';
import LoaderWrapper from '@components/shared/LoaderWrapper';
import SuccessModal from '@components/shared/SuccessModal';

// For using you need to pass contentObject for reported item
const ReportModal = ({
  isOpen,
  target,
  toggleModal,
  fetchList,
  reasons,
  loading
}) => {
  const [successPopup, setSuccessPopup] = useState(false);
  const toggleSuccessPopup = () => setSuccessPopup(!successPopup);

  useEffect(() => {
    fetchList();
  }, []);

  const handleSubmit = async values => {
    try {
      await API.post(target, values);
      toggleSuccessPopup();
      toggleModal();
    } catch (err) {
      Logger.error(err);
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={toggleModal}>
        <div className="report-modal">
          <Title className="report-modal__title" size="md">
            Report
          </Title>
          <LoaderWrapper isLoading={loading}>
            <Formik
              enableReinitialize
              initialValues={{ reason: get(reasons, '[0].id') }}
              onSubmit={handleSubmit}
            >
              {() => (
                <Form>
                  {reasons.map(reason => (
                    <FormikField
                      key={reason.id}
                      type="radio"
                      name="reason"
                      radioValue={reason.id}
                      label={reason.title}
                    />
                  ))}
                  <div className="row jc-center-xxs">
                    <Button className="report-modal__submit" type="submit">
                      Done
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </LoaderWrapper>
        </div>
      </Modal>
      <SuccessModal
        title="Your Report has been sent!"
        isOpen={successPopup}
        onClose={toggleSuccessPopup}
      />
    </>
  );
};

ReportModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  target: PropTypes.string,
  toggleModal: PropTypes.func.isRequired,
  fetchList: PropTypes.func.isRequired,
  reasons: PropTypes.array,
  loading: PropTypes.bool
};

const mst = ({ report: { isOpen, target, reasons, loading } }) => ({
  isOpen,
  target,
  reasons,
  loading
});

export default connect(mst, { toggleModal, fetchList })(ReportModal);

import * as Yup from 'yup';

export const RecipeSchema = Yup.object().shape({
  name: Yup.string()
    .label('Name')
    .required(),
  diets: Yup.array()
    .label('Diets')
    .nullable()
    .min(1)
    .max(20),
  ingredients: Yup.string()
    .label('Ingredients')
    .max(5000)
    .required(),
  directions: Yup.string()
    .label('Directions')
    .max(5000)
    .required(),
  additional_info: Yup.string()
    .label('Additional info')
    .max(5000)
});

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { closeModal } from '@store/ui/actions';
import modals from '@constants/modals';
import Title from '@components/shared/Title';
import Modal from '@components/shared/Modal';
import Button from '@components/shared/Button';

const SurveyABModal = ({ isOpen, closeModal, surveyData }) => {
  const handleClose = () => {
    closeModal(modals.AB_SURVEY_MODAL);
  };

  return (
    <Modal
      className="survey-ab-modal"
      isOpen={isOpen}
      closeIcon={true}
      onClose={handleClose}
    >
      <Title
        className="modal__title survey-ab-modal__title"
        heading="h3"
        size="md"
      >
        {surveyData.title_text}
      </Title>
      <div className="survey-ab-modal__subtitle">
        {surveyData.subtitle_text}
      </div>
      <img
        className="survey-ab-modal__img"
        src="/images/man-with-loupe-star.svg"
        alt="man-with-star"
      />
      <div className="survey-ab-modal__short">
        {surveyData.short_content_text}
      </div>
      <div
        className="survey-ab-modal__main"
        dangerouslySetInnerHTML={{ __html: surveyData.main_content_text }}
      />
      <Button color="yellow" onClick={handleClose}>
        {surveyData.cta_button}
      </Button>
    </Modal>
  );
};

SurveyABModal.propTypes = {
  closeModal: PropTypes.func,
  isOpen: PropTypes.bool,
  surveyData: PropTypes.object
};

const mapStateToProps = ({ ui: { openModals }, survey: surveyData }) => ({
  isOpen: openModals.includes(modals.AB_SURVEY_MODAL),
  surveyData
});

export default connect(mapStateToProps, { closeModal })(SurveyABModal);

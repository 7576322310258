import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Router from 'next/router';
import get from 'lodash/get';
import classNames from 'classnames';

import Logo from '@components/shared/Logo';
import Link from '@components/shared/Link';
import Icon from '@components/shared/Icon';
import Button from '@components/shared/Button';
import BubbleSelect from '@components/shared/BubbleSelect';
import Helpers from '@utils/Helpers';
import routes from '@constants/routes';
import Hotjar from '@utils/Hotjar';
import hjEvents from '@constants/hj-events';

const MobileHeader = ({
  route,
  user,
  logout,
  headerConfig,
  conditionOptions,
  currentCondition,
  toggleReviewModal
}) => {
  let [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    if (showMenu) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [showMenu]);

  const handleLogout = () => {
    logout({
      onSuccess: () => {
        setShowMenu(false);
      }
    });
  };

  const handleChangeCondition = condition => {
    if (condition && condition.slug) {
      Router.push(
        routes.conditions.details.pattern,
        routes.conditions.details.path(condition.slug)
      );
    } else {
      Router.push(routes.contactUs);
    }
    setShowMenu(false);
  };

  const favoritesCounter = get(user, 'favourites_count');
  const notificationsCounter = get(user, 'notifications_count');

  return (
    <div
      className={classNames('header-mobile', {
        'header-mobile--open': showMenu
      })}
    >
      <div className="header-mobile__header">
        <div className="header-mobile__logo">
          <Logo />
        </div>
        <div
          className="header-mobile__toggle"
          onClick={() => setShowMenu((showMenu = !showMenu))}
        >
          <Icon icon={showMenu ? 'close' : 'menu'} />
        </div>
      </div>
      <div className="header-mobile__wrapper">
        <div className="header-mobile__content">
          {(user && (
            <>
              <div className="header-mobile__conditions">
                <BubbleSelect
                  bold={true}
                  color="yellow"
                  options={conditionOptions}
                  value={currentCondition}
                  onChange={handleChangeCondition}
                  placeholder="Conditions"
                  instanceId="header-mobile-conditions"
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.id}
                />
              </div>
              <ul
                className="header-mobile__menu"
                onClick={() => setShowMenu(false)}
              >
                {user.profile_avatar && (
                  <>
                    <li>
                      <Link
                        href={routes.profile.my}
                        className="header-mobile__link"
                      >
                        My profile
                      </Link>
                    </li>
                    <li>
                      <Link
                        href={routes.profile.favorites}
                        className="header-mobile__link"
                      >
                        Favorites
                        {!!favoritesCounter && (
                          <span className="header-mobile__favorites">
                            {Helpers.counterLimiter(favoritesCounter)}
                          </span>
                        )}
                      </Link>
                    </li>
                    <li>
                      <Link
                        href={routes.notifications.list}
                        className="header-mobile__link"
                      >
                        Notifications
                        {!!notificationsCounter && (
                          <span className="header-mobile__notifications">
                            {Helpers.counterLimiter(notificationsCounter)}
                          </span>
                        )}
                      </Link>
                    </li>
                    <li>
                      <Link
                        href={routes.profile.settings}
                        className="header-mobile__link"
                      >
                        Settings
                      </Link>
                    </li>
                  </>
                )}
                <li>
                  <button
                    type="button"
                    onClick={handleLogout}
                    className="header-mobile__log-out"
                  >
                    Log out
                  </button>
                </li>
              </ul>
            </>
          )) || (
            <ul
              className="header-mobile__menu"
              onClick={() => setShowMenu(false)}
            >
              {!user && (
                <li>
                  <Link
                    href={routes.survey.welcome}
                    className="header-mobile__link"
                  >
                    <strong className="condition--contact-us">
                      <Icon icon="plus-slim" />
                      <span>Survey</span>
                    </strong>
                  </Link>
                </li>
              )}
              {Helpers.arrayHasItems(conditionOptions) &&
                conditionOptions.map(condition => {
                  const href = condition.slug
                    ? {
                        href: routes.conditions.details.pattern,
                        as: routes.conditions.details.path(condition.slug)
                      }
                    : {
                        href: routes.contactUs
                      };

                  return (
                    <li key={condition.id}>
                      <Link {...href} className="header-mobile__link">
                        {condition.name}
                      </Link>
                    </li>
                  );
                })}
              <li className="header-mobile__flat header-mobile__flat--about">
                <Link href={routes.about} className="header-mobile__link">
                  About
                </Link>
              </li>
              <li className="header-mobile__flat">
                <Link href={routes.blog.index} className="header-mobile__link">
                  Blog
                </Link>
              </li>
            </ul>
          )}
        </div>

        <div
          className="header-mobile__actions row ai-middle-xxs jc-center-xxs"
          onClick={() => setShowMenu(false)}
        >
          {user ? (
            <>
              {headerConfig.visibility.reviewButton && (
                <div className="col-xxs-12">
                  <Button
                    type="button"
                    className="header-mobile__btn"
                    onClick={toggleReviewModal}
                  >
                    Write a review
                  </Button>
                </div>
              )}
            </>
          ) : (
            <>
              {headerConfig.visibility.signIn && (
                <div className="col-xxs-6">
                  <Link
                    href={Helpers.authLinkRedirectWrapper(
                      routes.auth.signIn,
                      route
                    )}
                    bold={true}
                  >
                    Sign In
                  </Link>
                </div>
              )}
              {headerConfig.visibility.signUp && (
                <div className="col-xxs-6">
                  <Link
                    href={Helpers.authLinkRedirectWrapper(
                      routes.auth.signUp,
                      route
                    )}
                    bold={true}
                  >
                    Sign Up
                  </Link>
                </div>
              )}
              {headerConfig.visibility.createAccount && (
                <div className="col-xxs-6">
                  <Button
                    href={Helpers.authLinkRedirectWrapper(
                      routes.auth.signUp,
                      route
                    )}
                    onClick={() => Hotjar.vpv(hjEvents.createAccountHeader)}
                    className="header-mobile__btn"
                  >
                    Create Account
                  </Button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

MobileHeader.propTypes = {
  user: PropTypes.any,
  logout: PropTypes.func,
  route: PropTypes.object,
  headerConfig: PropTypes.object,
  currentCondition: PropTypes.object,
  conditionOptions: PropTypes.array,
  toggleReviewModal: PropTypes.func
};

export default MobileHeader;

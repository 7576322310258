import React from 'react';
import * as PropTypes from 'prop-types';

import Modal from '@components/shared/Modal';
import Title from '@components/shared/Title';
import Button from '@components/shared/Button';

const SuccessModal = ({ isOpen, onClose, title }) => (
  <Modal
    closeIcon={false}
    isOpen={isOpen}
    onClose={onClose}
    className="success-modal"
  >
    <>
      <Title size="md">{title}</Title>
      <Button color="yellow" onClick={onClose}>
        Close
      </Button>
    </>
  </Modal>
);

SuccessModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
};

export default SuccessModal;

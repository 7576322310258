import Router from 'next/router';

export const redirectTo = (server, to, code = 302) => {
  if (server) {
    server.writeHead(code, {
      Location: to
    });
    server.end();
  } else {
    Router.push(to);
  }
};

import { takeEvery, select, put } from 'redux-saga/effects';

import types from './types';
import { getFilters, getPagination } from './selects';

import API from '@utils/API';
import {
  fetchCombinationsSuccess,
  fetchCombinationsFailed
} from '@store/combinations/actions';
import { apiUrls } from '@constants/api';

function* asyncFetchList() {
  const filters = yield select(getFilters);
  const { page, page_size } = yield select(getPagination);

  let listUrl = apiUrls.combinations.list;

  try {
    const { data } = yield API.get(listUrl, {
      page,
      page_size,
      ...filters
    });
    yield put(fetchCombinationsSuccess(data));
  } catch (err) {
    yield put(fetchCombinationsFailed(err));
  }
}

const withPrefix = action => `${types.PREFIX}/${action}`;

export const combinationsSagas = [
  takeEvery(withPrefix(types.FETCH_COMBINATIONS), asyncFetchList)
];

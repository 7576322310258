import { takeEvery, select, put } from 'redux-saga/effects';
import sortBy from 'lodash/sortBy';

import types from './types';
import { getFilters, getPagination } from './selects';

import API from '@utils/API';
import {
  fetchCuresSuccess,
  fetchCuresFailed,
  fetchEffectivePeriodsSuccess
} from '@store/cures/actions';
import { apiUrls } from '@constants/api';
import Logger from '@utils/Logger';

function* asyncFetchCures() {
  const filters = yield select(getFilters);
  const { page, page_size } = yield select(getPagination);

  try {
    const { data } = yield API.get(apiUrls.cures.list, {
      page,
      page_size,
      ...filters
    });
    yield put(fetchCuresSuccess(data));
  } catch (err) {
    yield put(fetchCuresFailed(err));
  }
}

function* asyncFetchEffectivePeriods() {
  try {
    const res = yield API.get(apiUrls.cures.effectivePeriods);
    const sortedData = sortBy(res.data, 'id');
    yield put(fetchEffectivePeriodsSuccess(sortedData));
  } catch (err) {
    Logger.error(err);
  }
}

const withPrefix = action => `${types.PREFIX}/${action}`;

export const curesSagas = [
  takeEvery(withPrefix(types.FETCH_CURES), asyncFetchCures),
  takeEvery(
    withPrefix(types.FETCH_EFFECTIVE_PERIODS),
    asyncFetchEffectivePeriods
  )
];

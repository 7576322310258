import React from 'react';
import PropTypes from 'prop-types';

import { maxProfileOptionLength } from '@constants/common';
import FormikField from '@components/shared/FormikField';

const EditFormSelect = ({ hideControls, placeholder, ...props }) => {
  const components = hideControls ? { DropdownIndicator: () => null } : {};

  if (hideControls) {
    props.noOptionsMessage = () => null;
  }

  if (props.isCreatable) {
    props.isValidNewOption = (inputValue, selectValue, selectOptions) =>
      !(
        inputValue.trim().length === 0 ||
        selectOptions.find(option => option.name === inputValue) ||
        inputValue.trim().length >= maxProfileOptionLength
      );
  }

  return (
    <FormikField
      type="select"
      isMulti={true}
      isCreatable={true}
      getOptionLabel={option => (option.name ? option.name : option)}
      getOptionValue={option => (option.name ? option.name : option)}
      getNewOptionData={(value, label) => ({
        id: null,
        name: label
      })}
      className="edit-profile-form__field"
      placeholder={placeholder || 'Type something and press enter'}
      components={components}
      {...props}
    />
  );
};

EditFormSelect.propTypes = {
  placeholder: PropTypes.string,
  isCreatable: PropTypes.bool,
  hideControls: PropTypes.bool
};

export default EditFormSelect;

import { createActions } from 'redux-actions';

import types from './types';

const identityActions = [
  types.LEAVE_REVIEW,
  types.LEAVE_REVIEW_SUCCESS,
  types.SET_STEP,
  types.SET_DATA,
  types.SET_LAST_REVIEW,
  types.RESET_FLOW,
  types.TOGGLE_MODAL,
  types.SUBMIT_FORM,
  types.SET_REVIEW_OPTION,
  types.UPDATE_STEPPER,
  types.UPDATE_SUCCESS_BANNER,
  types.UPDATE_GUEST_BANNER,

  types.REMOVE_REVIEW,
  types.PATCH_REVIEW,
  types.CLAIM_DATA
];

export const {
  leaveReview,
  leaveReviewSuccess,
  setStep,
  setData,
  setLastReview,
  resetFlow,
  toggleModal,
  submitForm,
  setReviewOption,
  updateStepper,
  updateSuccessBanner,
  updateGuestBanner,
  removeReview,
  patchReview,
  claimData
} = createActions({}, ...identityActions, {
  prefix: types.PREFIX
});

import { createActions } from 'redux-actions';

import types from './types';

const identityActions = [
  types.FETCH_DIETS,
  types.FETCH_DIETS_SUCCESS,
  types.FETCH_SYMPTOMS,
  types.CLEAR_SYMPTOMS,
  types.FETCH_SYMPTOMS_SUCCESS
];

export const {
  fetchDiets,
  fetchDietsSuccess,
  fetchSymptoms,
  clearSymptoms,
  fetchSymptomsSuccess
} = createActions({}, ...identityActions, {
  prefix: types.PREFIX
});

import * as Yup from 'yup';

export const AdditionalInfoSchema = Yup.object().shape({
  cure_effect_period: Yup.string().label('How long until it worked?'),
  combinations: Yup.array()
    .label('Combined with')
    .max(5),
  helped_with: Yup.array()
    .label('Helped with')
    .max(5)
});

import { handleActions } from 'redux-actions';
import produce from 'immer';

import types from './types';

const initialState = {
  openModals: []
};

const reducer = {
  [types.TOGGLE_MODAL]: (state, { payload }) =>
    produce(state, draft => {
      if (draft.openModals.includes(payload)) {
        // eslint-disable-next-line no-param-reassign
        draft.openModals = draft.openModals.filter(m => m !== payload);
      } else {
        draft.openModals.push(payload);
      }
    }),
  [types.OPEN_MODAL]: (state, { payload }) =>
    produce(state, draft => {
      if (!draft.openModals.includes(payload)) {
        draft.openModals.push(payload);
      }
    }),
  [types.CLOSE_MODAL]: (state, { payload }) =>
    produce(state, draft => {
      draft.openModals = draft.openModals.filter(m => m !== payload);
    }),
  [types.CLOSE_ALL_MODALS]: state => ({
    ...state,
    openModals: []
  })
};

export const uiReducer = handleActions(reducer, initialState, {
  prefix: types.PREFIX
});

import axios from 'axios';

import routes from '../constants/routes';

import { USER_DATA_TOKEN_KEY, USER_SESSION_TOKEN_KEY } from '@constants/common';
import Cookies from '@utils/Cookies';

class AuthSession {
  static tokenKey = USER_SESSION_TOKEN_KEY;
  static userKey = USER_DATA_TOKEN_KEY;

  static isTokenSet(ctx) {
    const authToken = Cookies.get(AuthSession.tokenKey, ctx);
    return authToken && !!authToken.trim();
  }

  static setHeader(ctx) {
    if (AuthSession.isTokenSet(ctx)) {
      axios.defaults.headers.Authorization = `Token ${Cookies.get(
        AuthSession.tokenKey,
        ctx
      )}`;
    } else {
      AuthSession.removeHeader();
    }
  }

  static removeHeader() {
    axios.defaults.headers.Authorization = '';
  }

  static set(tokenValue) {
    Cookies.set(AuthSession.tokenKey, tokenValue);
    AuthSession.setHeader();
  }

  static getUserData(ctx) {
    return Cookies.get(AuthSession.userKey, ctx);
  }

  static setUserData(data, ctx) {
    Cookies.set(AuthSession.userKey, JSON.stringify(data), ctx);
  }

  static remove(ctx) {
    Cookies.remove(AuthSession.tokenKey, ctx);
    Cookies.remove(AuthSession.userKey, ctx);
    AuthSession.removeHeader();
  }

  static login(token, data) {
    AuthSession.set(token);
    AuthSession.setUserData(data);
    window.location = routes.home;
  }
}

export default AuthSession;

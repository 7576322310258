import get from 'lodash/get';
import * as dayjs from 'dayjs';
import pluralize from 'pluralize';
import isPlainObject from 'lodash/isPlainObject';
import isString from 'lodash/isString';
import isArray from 'lodash/isArray';
import htmlToText from 'html-to-text';
import isEmpty from 'lodash/isEmpty';

import routes from '@constants/routes';
import { dateFormats } from '@constants/common';
import { redirectTo } from '@components/RouterHelpers/redirectTo';
import AuthSession from '@utils/AuthSession';

function isClient() {
  return typeof window !== 'undefined';
}

function arrayHasItems(array) {
  return array && !!array.length;
}

function scrollToSection({ ref, offset = 0, animation = true }) {
  ref &&
    window.scrollTo({
      top: ref.current.offsetTop + offset,
      behavior: animation ? 'smooth' : 'auto'
    });
}

function isAuthRoute(route) {
  const authRoutes = [...Object.values(routes.auth), routes.survey.welcome];
  return authRoutes.includes(route);
}

function handleNonAuthQuery(err) {
  if (err.response && err.response.status === 401) {
    window.location.href = routes.home;
  }
}

function handleServerErrors(err) {
  let errors = {};

  if (err.response && err.response.data) {
    const { data } = err.response;

    if (typeof data !== 'string') {
      const { field_errors, non_field_errors } = data;

      if (non_field_errors) {
        errors.non_field_errors = Array.isArray(non_field_errors)
          ? non_field_errors.join(', ')
          : non_field_errors;
      }

      if (field_errors) {
        Object.keys(field_errors).forEach(key => {
          if (isPlainObject(field_errors[key])) {
            Object.keys(field_errors[key]).forEach(i => {
              errors[key] = Array.isArray(field_errors[key][i])
                ? field_errors[key][i].join(', ')
                : field_errors[key][i];
            });
          } else {
            errors[key] = Array.isArray(field_errors[key])
              ? field_errors[key].join(', ')
              : field_errors[key];
          }
        });
      }
    } else {
      errors = err.response.data;
    }
  }
  return errors;
}

function authLinkRedirectWrapper(url, route) {
  if (isAuthRoute(route.pathname) && route.query.redirect_to) {
    url = `${url}?redirect_to=${route.query.redirect_to}`;
  } else if (!isAuthRoute(route.pathname)) {
    url = `${url}?redirect_to=${route.pathname}`;
  }
  return url;
}

function yupFieldLabelExtractor(schema) {
  return function(fieldName) {
    return get(schema, ['fields', fieldName, '_label']);
  };
}

function formatCreatedDate(date) {
  const dateObj = dayjs(date);
  const hoursDiff = dayjs().diff(dateObj, 'hour');

  if (hoursDiff < 1) {
    const diffInMinutes = dayjs().diff(dateObj, 'minute');
    if (diffInMinutes < 1) {
      return '1 minute ago';
    }
    return `${diffInMinutes} ${pluralize('minute', diffInMinutes)} ago`;
  }

  if (hoursDiff < 24) {
    return `${hoursDiff} ${pluralize('hour', hoursDiff)} ago`;
  }

  return dayjs(date).format(dateFormats.SHORT);
}

function serverErrorHandler(err, ctx) {
  const { asPath } = ctx;
  const status = get(err, 'response.status');
  if (status === 404) {
    redirectTo(ctx.res, routes.pageNotFound);
  } else if (status === 401) {
    AuthSession.remove(ctx);
    redirectTo(ctx.res, asPath);
  } else {
    throw err;
  }
}

function cutString(string = '', chars, overflow = '...') {
  const overflowStr = string.length > chars ? overflow : '';
  return string.substr(0, chars) + overflowStr;
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function formatLikesAmount(amount) {
  if (!amount) {
    return 0;
  }
  if (amount < 1000) {
    return amount;
  } else {
    return `${parseFloat(amount / 1000).toFixed(1)}K`;
  }
}

function getUserLink(userId) {
  return {
    href: routes.profile.public.pattern,
    as: routes.profile.public.path(userId)
  };
}

function counterLimiter(countValue, limit = 1000) {
  return countValue < limit ? countValue : `${limit}+`;
}

function mbToBytes(mb) {
  return mb * 1048576;
}

function preventKeyEvent(keyEvent, keyCode = 13) {
  if ((keyEvent.charCode || keyEvent.keyCode) === keyCode) {
    keyEvent.preventDefault();
  }
}

function getLocaledNumber(number) {
  return Number(number).toLocaleString();
}

function absoluteUrl(req) {
  const protocol = 'https:';
  const host = req
    ? req.headers['x-forwarded-host'] || req.headers['host']
    : window.location.host;

  return {
    protocol: protocol,
    host: host,
    origin: protocol + '//' + host
  };
}

function formatMultipleIntegerFilter(value) {
  let multipleValue = [];

  if (isString(value)) {
    multipleValue = [value];
  }

  if (isArray(value)) {
    multipleValue = value;
  }

  return multipleValue.map(i => Number(i));
}

function formatMultipleStringFilter(value) {
  let multipleValue = [];

  if (isString(value)) {
    multipleValue = [value];
  }

  if (isArray(value)) {
    multipleValue = value;
  }

  return multipleValue;
}

function stripHtml(html) {
  return htmlToText.fromString(html, {
    uppercaseHeadings: false
  });
}

function objectValuesAreEmpty(values) {
  const objectValues = Object.values(values);
  return objectValues.every(isEmpty);
}

export default {
  isClient,
  isAuthRoute,
  arrayHasItems,
  scrollToSection,
  handleNonAuthQuery,
  handleServerErrors,
  yupFieldLabelExtractor,
  authLinkRedirectWrapper,
  capitalizeFirstLetter,
  formatCreatedDate,
  serverErrorHandler,
  cutString,
  formatLikesAmount,
  getUserLink,
  counterLimiter,
  mbToBytes,
  preventKeyEvent,
  getLocaledNumber,
  absoluteUrl,
  formatMultipleIntegerFilter,
  formatMultipleStringFilter,
  stripHtml,
  objectValuesAreEmpty
};

import React from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Button = ({
  icon,
  children,
  className,
  type,
  color,
  fluid,
  loader,
  disabled,
  onClick,
  href,
  hide,
  as,
  ...props
}) => {
  const Element = href ? 'a' : 'button';
  const Component = (
    <Element
      className={classnames(
        'btn',
        {
          'btn--fw': fluid,
          'btn--icon': icon,
          'btn--hide': hide,
          'btn--loading': loader,
          [`btn--${color}`]: color // dark, blue, yellow
        },
        className
      )}
      type={type}
      disabled={disabled}
      onClick={onClick || void 0}
      {...props}
    >
      {icon ? <i className={`icon icon-${icon}`} /> : children}
      {loader && <div className="btn__loader" />}
    </Element>
  );

  if (href) {
    return (
      <Link href={href} as={as}>
        {Component}
      </Link>
    );
  }

  return Component;
};

Button.defaultProps = {
  children: null,
  icon: null,
  className: '',
  type: 'button',
  href: null,
  title: null,
  disabled: false,
  hide: false,
  fluid: false,
  loader: false,
  target: null,
  color: 'dark'
};

Button.propTypes = {
  icon: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  hide: PropTypes.bool,
  fluid: PropTypes.bool,
  loader: PropTypes.bool,
  color: PropTypes.string,
  target: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.any,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  as: PropTypes.string
};

export default React.memo(Button);

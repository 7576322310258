import { createActions } from 'redux-actions';

import types from './types';

const identityActions = [
  types.FETCH_COMMENTS,
  types.FETCH_COMMENTS_SUCCESS,
  types.FETCH_COMMENTS_FAILED,
  types.CHANGE_URLS,
  types.RESET_URLS,
  types.CHANGE_PAGINATION,
  types.RESET_PAGINATION
];

export const {
  fetchComments,
  fetchCommentsSuccess,
  fetchCommentsFailed,
  changeUrls,
  resetUrls,
  changePagination,
  resetPagination
} = createActions({}, ...identityActions, {
  prefix: types.PREFIX
});

import { handleActions } from 'redux-actions';
import { clone } from 'lodash';

import types from './types';

const initialUrls = {
  getList: null
};

const initialPagination = {
  page: 1,
  count: null,
  page_size: 10
};

const initialState = {
  commentsList: [],
  commentsErrors: {},
  commentsLoading: false,
  urls: clone(initialUrls),
  pagination: clone(initialPagination)
};

const reducer = {
  [types.FETCH_COMMENTS]: state => ({
    ...state,
    commentsLoading: true
  }),
  [types.FETCH_COMMENTS_SUCCESS]: (state, { payload }) => ({
    ...state,
    commentsList: payload.results,
    commentsLoading: false,
    pagination: {
      ...state.pagination,
      count: payload.count
    }
  }),
  [types.FETCH_COMMENTS_FAILED]: (state, { payload }) => ({
    ...state,
    commentsErrors: payload,
    commentsLoading: false
  }),
  [types.CHANGE_URLS]: (state, { payload }) => ({
    ...state,
    urls: {
      ...state.urls,
      ...payload
    }
  }),
  [types.RESET_URLS]: state => ({
    ...state,
    urls: clone(initialUrls)
  }),
  [types.CHANGE_PAGINATION]: (state, { payload }) => ({
    ...state,
    pagination: {
      ...state.pagination,
      ...payload
    }
  }),
  [types.RESET_PAGINATION]: state => ({
    ...state,
    pagination: clone(initialPagination)
  })
};

export const commentsReducer = handleActions(reducer, initialState, {
  prefix: types.PREFIX
});

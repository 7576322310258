import React from 'react';
import * as classnames from 'classnames';
import * as PropTypes from 'prop-types';

import Button from '@components/shared/Button';
import Modal from '@components/shared/Modal';
import Title from '@components/shared/Title';
import Link from '@components/shared/Link';

const ConfirmationDialog = ({
  isOpen,
  onClose,
  onConfirm,
  inverted,
  title = 'Are you sure?',
  acceptText = 'Yes',
  declineText = 'No',
  children
}) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <>
      <Title size="md" className="confirmation-dialog__title">
        {title}
      </Title>
      <div className="confirmation-dialog__body">{children}</div>
      <div
        className={classnames('confirmation-dialog__controls', {
          'confirmation-dialog__controls--inverted': inverted
        })}
      >
        <Button onClick={onConfirm}>{acceptText}</Button>
        <Link bold onClick={onClose}>
          {declineText}
        </Link>
      </div>
    </>
  </Modal>
);

ConfirmationDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string,
  inverted: PropTypes.bool,
  acceptText: PropTypes.string,
  declineText: PropTypes.string,
  children: PropTypes.any
};

export default ConfirmationDialog;

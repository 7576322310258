import React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';

import { resetFlow } from '@store/user-review/actions';
import routes from '@constants/routes';
import Title from '@components/shared/Title';
import Text from '@components/shared/Text';
import Link from '@components/shared/Link';
import SocialAuth from '@components/Auth/SocialAuth';
import Button from '@components/shared/Button';

const GuestBanner = ({ guestBanner, resetFlow }) => {
  let loginQuery = {};

  if (guestBanner.redirectUri) {
    loginQuery.redirect_to = guestBanner.redirectUri;
  }

  return (
    <div className="leave-review__signup">
      <div className="row ai-middle-sm">
        <div className="col-xxs-12 col-xs-12 col-sm-5">
          <img
            className="leave-review__signup-img"
            src="/images/review-sign-up.svg"
            alt="sign up"
          />
        </div>
        <div className="col-xxs-12 col-xs-12 col-sm-7">
          <Title className="leave-review__signup-title" size="md">
            One more thing
          </Title>
          <Text>
            Join CureRate to post your review, discover ratings & reviews from
            people like you, and get personal recommendations. It&apos;s free!
          </Text>
        </div>
      </div>

      <div onClick={resetFlow}>
        <SocialAuth
          className="leave-review__signup-auth"
          query={loginQuery}
          customRender={() => (
            <Button
              className="social-auth__btn"
              color="blue"
              href={{
                pathname: routes.auth.signUp,
                query: loginQuery
              }}
            >
              <img
                src="/icons/envelope.svg"
                alt="email-logo"
                className="social-auth__logo"
              />
              Continue with Email
            </Button>
          )}
        />
        <Text size="sm" color="gray" className="leave-review__terms">
          By proceeding you agree to our{' '}
          <Link
            target="_blank"
            onClick={e => {
              e.stopPropagation();
            }}
            href={routes.flatpage.pattern}
            as={routes.flatpage.path(routes.flat.terms)}
          >
            Terms of Use
          </Link>{' '}
          and confirm you have read our{' '}
          <Link
            target="_blank"
            onClick={e => {
              e.stopPropagation();
            }}
            href={routes.flatpage.pattern}
            as={routes.flatpage.path(routes.flat.privacy)}
          >
            Privacy Policy
          </Link>
        </Text>
      </div>
      <div className="leave-review__signup-footer">
        Already with CureRate?{' '}
        <Link
          bold
          href={{
            pathname: routes.auth.signIn,
            query: loginQuery
          }}
          onClick={resetFlow}
        >
          Login
        </Link>
      </div>
    </div>
  );
};

const mapStateToProps = ({ userReview: { guestBanner } }) => ({
  guestBanner
});

GuestBanner.propTypes = {
  resetFlow: PropTypes.func,
  guestBanner: PropTypes.object
};

export default connect(mapStateToProps, { resetFlow })(GuestBanner);

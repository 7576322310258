import { takeEvery, select, put, call, debounce } from 'redux-saga/effects';
import get from 'lodash/get';

import types from './types';
import {
  setLastReview,
  leaveReviewSuccess,
  updateSuccessBanner,
  setStep as setReviewStep,
  toggleModal as toggleReviewModal
} from './actions';

import API from '@utils/API';
import Logger from '@utils/Logger';
import GuestReviews from '@utils/GuestReviews';
import { apiUrls } from '@constants/api';
import { cureTypes } from '@constants/common';
import { reviewFlows, reviewSteps } from '@constants/review';
import routes from '@constants/routes';
import Hotjar from '@utils/Hotjar';
import hjEvents from '@constants/hj-events';

function* asyncLeaveReview({ payload }) {
  try {
    const filters = yield select(({ review }) => review.data);
    const res = yield call(API.get, apiUrls.activities.reviews.list, filters);
    yield put(leaveReviewSuccess(res.data));
    payload.onSuccess && payload.onSuccess(res.data);
  } catch (err) {
    Logger.error(err);
  }
}

function* asyncSubmitReview({ payload }) {
  try {
    const flowType = yield select(({ userReview }) => userReview.flowType);
    const data = yield select(({ userReview }) => userReview.data);
    const user = yield select(({ auth }) => auth.user);

    const isNewCure = data.isNewCure;

    const requestData = {
      ...data,
      photos: data.photos.map(i => i.id)
    };

    const res = yield call(
      API.post,
      apiUrls.activities.reviews.list,
      requestData
    );

    if (user) {
      /*
        Show popup without caption for recipes
        and if user goes into post a review scenario
      */
      const showImmediateSuccessPopup =
        get(data, 'type.entry') === cureTypes.recipe && isNewCure;

      if (showImmediateSuccessPopup) {
        yield put(setReviewStep(reviewSteps.successImmediate));
      } else {
        if (flowType === reviewFlows.review) {
          yield put(
            updateSuccessBanner({
              title: 'You just helped someone!',
              description: 'Thanks for your review. Really.',
              redirectUri: {
                href: routes.cures.details.pattern,
                as: routes.cures.details.path(data.cure)
              }
            })
          );
        } else {
          yield put(
            updateSuccessBanner({
              redirectUri: routes.profile.my
            })
          );
        }
        yield put(setReviewStep(reviewSteps.success));
      }
    } else {
      Hotjar.vpv(hjEvents.reviewSubmitRegistrationModal);
      // Set review id and cure slug to cookies
      GuestReviews.setReview({ review: res.data.id, cure: data.cure });
      yield put(setReviewStep(reviewSteps.guestBanner));
    }
    yield put(setLastReview(res.data));
    payload.onSuccess && payload.onSuccess(res.data);
  } catch (err) {
    payload.onFailed && payload.onFailed(err);
    Logger.error(err);
  }
}

function* asyncRemoveReview({ payload: { review, onSuccess } }) {
  try {
    yield call(API.delete, apiUrls.activities.reviews.details(review.id));
    onSuccess && onSuccess();
  } catch (err) {
    Logger.error(err);
  }
}

function* asyncPatchReview({ payload: { review, onSuccess, onFailed } }) {
  try {
    const res = yield call(
      API.patch,
      apiUrls.activities.reviews.details(review.id),
      review
    );
    onSuccess && onSuccess(res.data);
  } catch (err) {
    onFailed && onFailed(err);
    Logger.error(err);
  }
}

function* asyncClaimData({ payload }) {
  try {
    const {
      userGuestReviewObj,
      userGuestReview,
      userGuestProduct,
      userGuestStory,
      userGuestRecipe
    } = payload;

    // We have 2 types of success popups depends on added cure type, this let use for changing result success popup type
    let useSuccessImmediateSuccess = false;

    if (userGuestReview) {
      yield call(API.post, apiUrls.activities.reviews.claim(userGuestReview));
      GuestReviews.removeReview();

      if (!userGuestProduct) {
        const reviewTargetCure = get(userGuestReviewObj, 'cure');
        // Additional condition for specific case - is user leave only review for exist product
        const bannerConfig = {
          title: 'You just helped someone!',
          description: 'Thanks for your review. Really.'
        };
        //  Success popup will redirect user to this cure details page - additional check for null, undefined, etc
        if (reviewTargetCure) {
          bannerConfig.redirectUri = {
            href: routes.cures.details.pattern,
            as: routes.cures.details.path(reviewTargetCure)
          };
        }
        yield put(updateSuccessBanner(bannerConfig));
      } else {
        // If user add new product - it needs admin confirmation - redirect user to profile when he can see pending review
        yield put(
          updateSuccessBanner({
            redirectUri: routes.profile.my
          })
        );
      }
    }
    if (userGuestProduct) {
      yield call(API.post, apiUrls.cures.claim(userGuestProduct));
      GuestReviews.removeProduct();
    }
    if (userGuestRecipe) {
      useSuccessImmediateSuccess = true;
      yield call(API.post, apiUrls.cures.claim(userGuestRecipe));
      GuestReviews.removeRecipe();
    }
    if (userGuestStory) {
      yield put(
        updateSuccessBanner({
          title: 'Thanks for sharing your success!'
        })
      );
      useSuccessImmediateSuccess = true;
      yield call(API.post, apiUrls.combinations.claim(userGuestStory));
      GuestReviews.removeSuccessStory();
    }
    if (useSuccessImmediateSuccess) {
      yield put(setReviewStep(reviewSteps.successImmediate));
    } else {
      yield put(setReviewStep(reviewSteps.success));
    }
    yield put(toggleReviewModal());
  } catch (err) {
    Logger.error(err);
  } finally {
    GuestReviews.removeReview();
    GuestReviews.removeProduct();
    GuestReviews.removeRecipe();
    GuestReviews.removeSuccessStory();
  }
}

const withPrefix = action => `${types.PREFIX}/${action}`;

export const reviewSagas = [
  takeEvery(withPrefix(types.LEAVE_REVIEW), asyncLeaveReview),
  takeEvery(withPrefix(types.SUBMIT_FORM), asyncSubmitReview),
  takeEvery(withPrefix(types.REMOVE_REVIEW), asyncRemoveReview),
  takeEvery(withPrefix(types.PATCH_REVIEW), asyncPatchReview),
  debounce(1000, withPrefix(types.CLAIM_DATA), asyncClaimData)
];

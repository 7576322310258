import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { toggleModal as toggleReviewModal } from '@store/user-review/actions';
import Logo from '@components/shared/Logo';
import FooterList from '@components/shared/Footer/FooterList';
import routes from '@constants/routes';

const Footer = ({ conditionsList, toggleReviewModal }) => {
  const currentYear = new Date().getFullYear();
  const conditions = conditionsList.map(condition => ({
    label: condition.name,
    href: routes.conditions.details.pattern,
    as: routes.conditions.details.path(condition.slug)
  }));

  const common = [
    {
      label: 'About',
      protected: false,
      href: routes.about
    },
    {
      label: 'Review a Product',
      protected: true,
      onClick() {
        toggleReviewModal();
      }
    },
    {
      label: 'Contact the Team',
      protected: false,
      href: routes.contactUs
    },
    {
      label: 'Privacy Policy',
      protected: false,
      href: routes.flatpage.pattern,
      as: routes.flatpage.path(routes.flat.privacy)
    },
    {
      label: 'Terms of Use',
      protected: false,
      href: routes.flatpage.pattern,
      as: routes.flatpage.path(routes.flat.terms)
    },
    {
      label: 'Blog',
      protected: false,
      href: routes.blog.index
    }
  ];

  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-xxs-12 col-md-3">
            <div className="footer__logo">
              <Logo />
            </div>
          </div>
          <div className="col-xxs-12 col-xs-6 col-md-3">
            <div className="footer__item">
              <h3 className="footer__title">Conditions</h3>
              <FooterList list={conditions} className="footer__list" />
            </div>
          </div>
          <div className="col-xxs-12 col-xs-6 col-md-3">
            <div className="footer__item">
              <h3 className="footer__title">Curerate</h3>
              <FooterList list={common} className="footer__list" />
            </div>
          </div>
          <div className="col-xxs-12 col-md-3">
            <div className="footer__item">
              <p className="footer__text">
                CureRate — Relevant, Reliable Remedies
              </p>
              <p className="footer__copyright">
                &copy; {currentYear} All Rights Reserved
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  conditionsList: PropTypes.array,
  toggleReviewModal: PropTypes.func
};

const mapStateToProps = ({ conditions: { conditionsList } }) => ({
  conditionsList
});

export default connect(mapStateToProps, { toggleReviewModal })(Footer);

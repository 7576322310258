import { takeEvery, put, call } from 'redux-saga/effects';

import types from './types';
import { fetchListSuccess } from './actions';

import Logger from '@utils/Logger';
import API from '@utils/API';
import { apiUrls } from '@constants/api';

function* asyncFetchList() {
  try {
    const res = yield call(API.get, apiUrls.activities.reports.reasons);
    yield put(fetchListSuccess(res.data));
  } catch (err) {
    Logger.error(err);
  }
}

const withPrefix = action => `${types.PREFIX}/${action}`;

export const reportSagas = [
  takeEvery(withPrefix(types.FETCH_LIST), asyncFetchList)
];

import { handleActions } from 'redux-actions';

import types from './types';

const initialState = {
  title_text: '',
  subtitle_text: '',
  short_content_text: '',
  main_content_text: '',
  cta_button: ''
};

const reducer = {
  [types.SET_MODAL_DATA]: (state, { payload }) => ({
    ...state,
    ...payload
  })
};

export const surveyReducer = handleActions(reducer, initialState, {
  prefix: types.PREFIX
});

import * as Yup from 'yup';

const SetupProfileFormSchema = userEmail =>
  Yup.object().shape({
    username: Yup.string()
      .label('Username')
      .min(2)
      .max(20)
      .required(),
    email: userEmail
      ? Yup.string()
      : Yup.string()
          .label('Email')
          .email()
          .required(),
    system_avatar: Yup.string()
      .label('Avatar')
      .nullable()
      .required('Please select an avatar'),
    disease_conditions: Yup.array()
      .nullable()
      .label('Condition')
      .required()
  });

export { SetupProfileFormSchema };

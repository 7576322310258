import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import API from '@utils/API';
import Logger from '@utils/Logger';
import Avatar from '@components/shared/Avatar';
import Error from '@components/shared/Error';
import LoaderWrapper from '@components/shared/LoaderWrapper';
import { apiUrls } from '@constants/api';

const Avatars = ({
  name,
  label,
  error,
  onChange,
  selected,
  className,
  selectedFile,
  defaultAvatar
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [avatarsList, setAvatarsList] = useState([]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const { data } = await API.get(apiUrls.files.avatars);
        setAvatarsList(data);
        setIsLoading(false);
      } catch (err) {
        Logger.error(err);
        setIsLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (avatarsList.length) {
      if (defaultAvatar) {
        onChange(avatarsList[0].id);
      }

      if (selectedFile) {
        const selectedAvatar = avatarsList.find(i => i.file === selectedFile);
        if (selectedAvatar) {
          onChange(selectedAvatar.id);
        }
      }
    }
  }, [selectedFile, avatarsList]);

  return (
    <div className={classNames('avatars', className)}>
      {label && <p className="avatars__label">{label}</p>}
      <LoaderWrapper isLoading={isLoading}>
        <div className="avatars__content">
          <div className="avatars__list">
            {avatarsList.map(item => (
              <div className="avatars__item" key={item.id}>
                <label className="avatars__label">
                  <Avatar src={item.file} active={selected == item.id} />
                  <input
                    type="radio"
                    name={name}
                    value={item.id}
                    onChange={e => onChange(e.target.value)}
                    className="avatars__radio"
                  />
                </label>
              </div>
            ))}
          </div>
        </div>
      </LoaderWrapper>
      {error && <Error text={error} nonField={true} />}
    </div>
  );
};

Avatars.defaultProps = {
  name: 'system_avatar',
  defaultAvatar: false,
  onChange: () => null
};

Avatars.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  selected: PropTypes.any,
  onChange: PropTypes.func,
  className: PropTypes.string,
  defaultAvatar: PropTypes.bool,
  selectedFile: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default Avatars;

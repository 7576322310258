import { createSelector } from 'reselect';

import { cureTypes } from '@constants/common';

export const getFilters = state => state.cures.filters;
export const getPagination = state => state.cures.pagination;
export const getCureTypes = cures => cures.typesList;

export const cureTypesWithoutStories = createSelector(getCureTypes, items =>
  items.filter(type => type.entry !== cureTypes.combination)
);

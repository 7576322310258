export const BASE_URL = process.env.NEXT_PUBLIC_API_URL || '';

export const apiUrls = {
  auth: {
    signIn: '/auth/login/',
    signUp: '/auth/register/',
    passwordReset: '/auth/password/reset/',
    passwordResetConfirm: '/auth/password/reset/confirm/',
    changePassword: '/auth/password/change/',
    changeEmail: '/auth/register/change-email/',
    verifyEmail: '/auth/register/verify-email/',
    unsubscribe: '/auth/unsubscribe/',
    logout: '/auth/logout/',
    googleAuth: '/auth/google/',
    facebookAuth: '/auth/facebook/'
  },
  files: {
    avatars: '/files/system-avatars/',
    curePhotos: '/files/cure-photos/',
    reviewsPhotos: '/files/review-photos/'
  },
  conditions: {
    list: '/disease-conditions/',
    conditionTags: `/disease-conditions/as-article-tags/`,
    details: condition => `/disease-conditions/${condition}/`
  },
  profile: {
    my: '/profiles/my/',
    short: 'profiles/my/short/',
    details: id => `/profiles/${id}/`,
    subscribe: 'profiles/subscribe/'
  },
  cures: {
    claim: slug => `/cures/${slug}/claim/`,
    effectivePeriods: '/cure-effective-periods/',
    favorite: id => `/cures/${id}/add-to-favourite/`,
    removeFavorite: id => `/cures/${id}/remove-from-favourite/`,
    createSupplement: '/cures/create-supplement/',
    createProduct: '/cures/create-product/',
    createRecipe: '/cures/create-recipe/',
    list: '/cures/',
    types: '/cure-types/',
    counts: '/cures/counts/',
    search: '/cures/search/',
    tags: '/cures/tags/',
    categories: '/cures/categories/',
    favorites: '/cures/favourites/',
    details: id => `/cures/${id}/`,
    comments: slug => `/cures/${slug}/comments/`,
    like: slug => `/cures/${slug}/like/`,
    removeLike: slug => `/cures/${slug}/remove-like/`
  },
  combinations: {
    list: '/cure-success-combinations/',
    create: '/cure-success-combinations/',
    details: id => `/cure-success-combinations/${id}/`,
    like: id => `/cure-success-combinations/${id}/like/`,
    removeLike: id => `/cure-success-combinations/${id}/remove-like/`,
    claim: id => `/cure-success-combinations/${id}/claim/`,
    comments: {
      list: id => `/cure-success-combinations/${id}/comments/`,
      create: id => `/cure-success-combinations/${id}/comments/`
    }
  },
  common: {
    diets: '/diets/',
    dietTags: '/diets/as-tags/',
    symptoms: '/symptoms/'
  },
  activities: {
    likes: '/activities/likes/',
    removeLikes: (id, type) => `/activities/likes/${id}/${type}/`,
    reviews: {
      my: '/activities/reviews/my/',
      claim: id => `/activities/reviews/${id}/claim/`,
      list: '/activities/reviews/',
      details: id => `/activities/reviews/${id}/`,
      like: id => `/activities/reviews/${id}/like/`,
      removeLike: id => `/activities/reviews/${id}/remove-like/`,
      report: id => `/activities/reviews/${id}/report/`,
      comments: {
        list: id => `/activities/reviews/${id}/comments/`,
        create: id => `/activities/reviews/${id}/comments/`
      }
    },
    comments: {
      like: id => `/activities/comments/${id}/like/`,
      removeLike: id => `/activities/comments/${id}/remove-like/`,
      update: id => `/activities/comments/${id}/`,
      delete: id => `/activities/comments/${id}/`,
      report: id => `/activities/comments/${id}/report/`
    },
    reports: {
      reasons: '/activities/reports-reasons/'
    }
  },
  notifications: {
    list: '/notifications/',
    details: id => `/notifications/${id}/`,
    markAsReviewed: '/notifications/mark-as-reviewed/',
    deviceTokens: '/device-tokens/'
  },
  flatpages: {
    list: '/flatpages/',
    details: slug => `/flatpages/${slug}/`
  },
  blog: {
    list: '/blog/',
    article: slug => `/blog/${slug}/`
  },
  survey: {
    config: '/survey/config/',
    popup: id => `/survey/popup/${id}/`
  },
  contactUs: '/contact-us/',
  sitemap: '/sitemap.xml'
};

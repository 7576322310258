import { createActions } from 'redux-actions';

import types from './types';

const identityActions = [
  types.TOGGLE_MODAL,
  types.FETCH_LIST,
  types.FETCH_LIST_SUCCESS
];

export const { toggleModal, fetchList, fetchListSuccess } = createActions(
  {},
  ...identityActions,
  {
    prefix: types.PREFIX
  }
);

import React from 'react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactSelect, { components } from 'react-select';

import Icon from '@components/shared/Icon';

const DropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <Icon icon="chevron-down" />
  </components.DropdownIndicator>
);
const ClearIndicator = props => (
  <components.ClearIndicator {...props}>
    <Icon icon="close" />
  </components.ClearIndicator>
);
const MultiValueRemove = props => (
  <components.MultiValueRemove {...props}>
    <Icon icon="close" />
  </components.MultiValueRemove>
);

const Select = ({
  name,
  error,
  value,
  onChange,
  getOptionValue,
  visibleIndicators,
  components,
  ...props
}) => {
  const handleChange = data => {
    let value = data;

    if (!value && props.isMulti) {
      value = [];
    }

    onChange({
      target: {
        name: name,
        value: value
      }
    });
  };

  return (
    <div
      className={classNames('select', {
        'select--error': error,
        'select--visible-indicators': visibleIndicators
      })}
    >
      <ReactSelect
        isClearable={true}
        onChange={handleChange}
        classNamePrefix="select"
        className="select__select`"
        value={!isEmpty(value) && value}
        getOptionValue={getOptionValue}
        components={{
          DropdownIndicator,
          ClearIndicator,
          MultiValueRemove,
          ...components
        }}
        {...props}
      />
    </div>
  );
};
Select.defaultProps = {
  visibleIndicators: false,
  getOptionValue: option => option.value
};
Select.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  useValues: PropTypes.bool,
  isMulti: PropTypes.bool,
  getOptionValue: PropTypes.func,
  visibleIndicators: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object
  ]),
  components: PropTypes.any
};
export default Select;

import React, { forwardRef } from 'react';
import classnames from 'classnames';
import * as PropTypes from 'prop-types';

// eslint-disable-next-line react/display-name
const Textarea = forwardRef(({ className, error, ...props }, ref) => {
  const wrapperClassName = classnames('field', className, {
    'field--error': error
  });

  return (
    <div className={wrapperClassName}>
      <div className="field-textarea">
        <textarea className="field-textarea__field" ref={ref} {...props} />
      </div>
    </div>
  );
});

Textarea.defaultProps = {
  value: '',
  onChange: () => null
};

Textarea.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  error: PropTypes.any,
  className: PropTypes.string,
  label: PropTypes.string
};

export default Textarea;

const reviewSteps = {
  selectCure: 'selectProduct',
  addCure: 'addCure',
  addDescription: 'addDescription',
  additionalInfo: 'additionalInfo',
  recommendations: 'recommendations',
  guestBanner: 'guestBanner',
  success: 'success',
  successImmediate: 'successImmediate'
};

const reviewFlows = {
  product: 'product', // Add product and offer review from list page
  fullProduct: 'fullProduct', // Add product and offer review with type and condition fileds from list page
  review: 'review', // Review from product details flow
  writeReview: 'writeReview' // full flow from header button
};

export { reviewSteps, reviewFlows };

import { handleActions } from 'redux-actions';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';

import types from './types';

import { reviewFlows, reviewSteps } from '@constants/review';
import GuestReviews from '@utils/GuestReviews';
import Ga from '@utils/Ga';
import gaEvents from '@constants/ga-events';

const initialData = {
  step: reviewSteps.selectCure,
  flowType: reviewFlows.writeReview,
  isOpen: false,
  reviewIsSkippable: false,
  stepper: {
    current: 1,
    steps: 3
  },
  data: {
    type: null,
    condition: null,
    cure: null,
    title: '',
    body: '',
    score: 0,
    cure_effect_period: null,
    combinations: [],
    helped_with: [],
    alternatives: [],
    photos: [],
    modifications: [],
    isNewCure: false,
    cure_title: null
  },
  successBanner: {
    title: 'Thanks for your recommendation!',
    description: null,
    redirectUri: null,
    callback: null
  },
  guestBanner: {
    redirectUri: undefined
  },
  lastAddedReview: null
};

const initialState = cloneDeep(initialData);

const reducer = {
  [types.SET_STEP]: (state, { payload }) => ({
    ...state,
    step: payload
  }),
  [types.SET_DATA]: (state, { payload }) => ({
    ...state,
    data: {
      ...state.data,
      ...payload
    }
  }),
  [types.SET_LAST_REVIEW]: (state, { payload }) => ({
    ...state,
    lastAddedReview: payload
  }),
  [types.TOGGLE_MODAL]: state => {
    const userGuestReviewObj = GuestReviews.getReview(); // Can be null

    const userGuestReview = get(userGuestReviewObj, 'review');
    const userGuestProduct = GuestReviews.getProduct();
    const userGuestStory = GuestReviews.getSuccessStory();
    const userGuestRecipe = GuestReviews.getRecipe();

    // If guest has unfinished review / suggestion flow - show guest banner
    if (
      userGuestReview ||
      userGuestProduct ||
      userGuestStory ||
      userGuestRecipe
    ) {
      return {
        ...state,
        step: reviewSteps.guestBanner,
        isOpen: true
      };
    }

    if (!state.isOpen) {
      Ga.sendReviewEvent(gaEvents.openReviewModal);
    }

    return {
      ...state,
      isOpen: !state.isOpen
    };
  },
  [types.RESET_FLOW]: () => cloneDeep(initialState),
  [types.SET_REVIEW_OPTION]: (state, { payload }) => ({
    ...state,
    ...payload
  }),
  [types.UPDATE_SUCCESS_BANNER]: (state, { payload }) => ({
    ...state,
    successBanner: {
      ...state.successBanner,
      ...payload
    }
  }),
  [types.UPDATE_GUEST_BANNER]: (state, { payload }) => ({
    ...state,
    guestBanner: {
      ...state.guestBanner,
      ...payload
    }
  }),
  [types.UPDATE_STEPPER]: (state, { payload }) => ({
    ...state,
    stepper: {
      ...state.stepper,
      ...payload
    }
  })
};

export const reviewReducer = handleActions(reducer, initialState, {
  prefix: types.PREFIX
});

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';

import Icon from '@components/shared/Icon';

const test = props => (
  <components.NoOptionsMessage {...props}>
    <Icon icon="chevron-down" />
  </components.NoOptionsMessage>
);

class Select extends React.Component {
  state = {
    inputText: '',
    emptyMessage: ''
  };

  componentDidMount() {
    const { inputValue, noOptionsMessage, minStringLength } = this.props;
    this.setState({
      inputText: inputValue || this.props.initialInputValue,
      emptyMessage: noOptionsMessage(minStringLength)
    });
  }

  clearInputText = () => {
    this.setState({ inputText: '' });
  };

  handleInputChange = (newText, { action }) => {
    switch (action) {
      case 'input-change':
        this.setState({ inputText: newText });
        break;
      case 'set-value':
        this.setState({ inputText: '' });
        break;
      case 'input-blur':
        break;
      case 'menu-close':
        break;
    }
  };

  handleLoadOptions = (text, callback) => {
    const { inputText } = this.state;
    const {
      loadOptions,
      minStringLength,
      noOptionsMessage,
      noResultsMessage
    } = this.props;

    if (inputText.length < Number(minStringLength)) {
      this.setState({ emptyMessage: noOptionsMessage(minStringLength) });
      callback(null);
    } else {
      this.setState({ emptyMessage: noResultsMessage() });
      loadOptions(text, callback);
    }
  };

  render() {
    let {
      color,
      options,
      onSearch,
      searchIcon,
      showSearchIcon,
      showSearchButton,
      searchButtonIcon,
      components,
      // eslint-disable-next-line no-unused-vars
      loadOptions,
      // eslint-disable-next-line no-unused-vars
      noOptionsMessage,
      ...props
    } = this.props;

    const { inputText, emptyMessage } = this.state;

    return (
      <div
        className={classNames('autocomplete', {
          [`autocomplete--${color}`]: color,
          'autocomplete--btn-icon': searchButtonIcon,
          'autocomplete--btn-text': !searchButtonIcon,
          'autocomplete--icon': searchIcon && showSearchIcon
        })}
      >
        {showSearchIcon && (
          <Icon icon={searchIcon} className="autocomplete__icon" />
        )}
        <AsyncSelect
          inputValue={inputText}
          cacheOptions={true}
          defaultOptions={options}
          defaultInputValue={inputText}
          loadOptions={this.handleLoadOptions}
          classNamePrefix="autocomplete"
          className="autocomplete__select"
          onInputChange={this.handleInputChange}
          noOptionsMessage={() => emptyMessage}
          components={{
            DropdownIndicator: null,
            IndicatorSeparator: null,
            test,
            ...components
          }}
          {...props}
        />
        {showSearchButton && (
          <button
            type="button"
            onClick={() => onSearch(inputText)}
            className={classNames('autocomplete__btn', {
              'autocomplete__btn--icon': searchButtonIcon,
              'autocomplete__btn--text': !searchButtonIcon
            })}
          >
            {(searchButtonIcon && (
              <Icon icon="search" className="autocomplete__icon" />
            )) ||
              'Search'}
          </button>
        )}
      </div>
    );
  }
}

Select.defaultProps = {
  initialInputValue: '',
  color: 'yellow',
  searchIcon: 'search',
  minStringLength: 3,
  options: true,
  onSearch: () => null,
  showSearchIcon: true,
  showSearchButton: true,
  searchButtonIcon: false,
  noResultsMessage: text => `No results for "${text}"`,
  noOptionsMessage: count => `Please enter ${count} or more characters`
};

Select.propTypes = {
  name: PropTypes.string,
  initialInputValue: PropTypes.string,
  inputValue: PropTypes.string,
  searchIcon: PropTypes.string,
  showSearchIcon: PropTypes.bool,
  showSearchButton: PropTypes.bool,
  noResultsMessage: PropTypes.func,
  noOptionsMessage: PropTypes.oneOfType([PropTypes.func]),
  minStringLength: PropTypes.number,
  groupBy: PropTypes.string,
  onSearch: PropTypes.func,
  loadOptions: PropTypes.func,
  searchButtonIcon: PropTypes.bool,
  color: PropTypes.oneOf(['yellow', 'blue', 'primary']),
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  options: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object
  ]),
  components: PropTypes.any
};

export default Select;

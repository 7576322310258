import axios from 'axios';
import Router from 'next/router';
import qs from 'query-string';

import { BASE_URL } from '../constants/api';
import routes from '../constants/routes';
import { storeInstance } from '../pages/_app';

import helpers from './Helpers';

import AuthSession from '@utils/AuthSession';
import { toggleModal } from '@store/ui/actions';
import MODALS from '@constants/modals';

axios.defaults.headers['Content-Type'] = 'application/json';
axios.defaults.headers['Accept-Language'] = 'en';
axios.defaults.baseURL = BASE_URL;

axios.interceptors.response.use(
  res => res,
  err => {
    if (err.response) {
      switch (err.response.status) {
        case 401:
          if (helpers.isClient()) {
            if (AuthSession.isTokenSet()) {
              AuthSession.remove();
              Router.reload();
            } else {
              storeInstance.dispatch(toggleModal(MODALS.NON_AUTH_USER));
            }
          } else {
            throw err;
          }
          break;
        case 404:
          if (helpers.isClient()) {
            Router.push(routes.pageNotFound);
          } else {
            throw err;
          }
          break;
        default:
          break;
      }
    }
    return Promise.reject(
      helpers.isClient() ? helpers.handleServerErrors(err) : err
    );
  }
);

class API {
  static get(url, params, options = {}) {
    return axios({
      method: 'GET',
      url: encodeURI(url),
      params,
      ...options,
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      }
    });
  }

  static post(url, data) {
    return axios({
      method: 'POST',
      url: encodeURI(url),
      data
    });
  }

  static patch(url, data) {
    return axios({
      method: 'PATCH',
      url: encodeURI(url),
      data
    });
  }

  static put(url, data) {
    return axios({
      method: 'PUT',
      url: encodeURI(url),
      data
    });
  }

  static delete(url, data) {
    return axios({
      method: 'DELETE',
      url: encodeURI(url),
      data
    });
  }
}

export default API;

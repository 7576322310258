export default {
  home: '/',
  auth: {
    signIn: '/auth/sign-in',
    signUp: '/auth/sign-up',
    passwordReset: '/auth/password-reset',
    passwordResetConfirm: '/auth/password-reset-confirm'
  },
  cures: {
    list: {
      pattern: '/cures/list/[condition]/[type]',
      path: (condition, type) => `/cures/list/${condition}/${type}`
    },
    details: {
      pattern: '/cures/[id]',
      path: id => `/cures/${id}`
    },
    edit: {
      pattern: '/cures/edit/[id]',
      path: id => `/cures/edit/${id}`
    },
    search: {
      pattern: '/cures/search/[disease_condition]',
      path: disease_condition => `/cures/search/${disease_condition}`
    }
  },
  conditions: {
    details: {
      pattern: '/conditions/[condition]',
      path: condition => `/conditions/${condition}`
    }
  },
  combinations: {
    list: {
      pattern: '/combinations/[condition]',
      path: condition => `/combinations/${condition}`
    },
    create: {
      pattern: '/combinations/[condition]/create',
      path: condition => `/combinations/${condition}/create`
    },
    details: {
      pattern: '/combinations/[condition]/[id]',
      path: (condition, id) => `/combinations/${condition}/${id}`
    },
    edit: {
      pattern: '/combinations/[condition]/edit/[id]',
      path: (condition, id) => `/combinations/${condition}/edit/${id}`
    }
  },
  profile: {
    my: '/profile',
    edit: '/profile/edit',
    reviews: {
      pattern: '/profile/reviews/[id]',
      path: id => `/profile/reviews/${id}`
    },
    favorites: '/profile/favorites',
    public: {
      pattern: '/profile/details/[id]',
      path: id => `/profile/details/${id}`
    },
    settings: '/profile/settings'
  },
  reviews: {
    details: {
      pattern: '/reviews/[id]',
      path: id => `/reviews/${id}`
    },
    edit: {
      pattern: '/reviews/edit/[id]',
      path: id => `/reviews/edit/${id}`
    }
  },
  notifications: {
    list: '/notifications'
  },
  about: '/about',
  contactUs: '/contact-us',
  flatpage: {
    pattern: '/[slug]',
    path: slug => `/${slug}`
  },
  flat: {
    terms: 'terms-of-use',
    privacy: 'privacy-policy'
  },
  blog: {
    index: '/blog',
    article: {
      pattern: `/blog/[slug]`,
      path: slug => `/blog/${slug}`
    },
    articleAmp: {
      pattern: `/blog/amp/[slug]`,
      path: slug => `/blog/amp/${slug}`
    }
  },
  survey: {
    welcome: '/survey/welcome',
    profile: '/survey/profile'
  },
  pageNotFound: '/404'
};

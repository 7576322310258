import React from 'react';
import NextLink from 'next/link';
import classNames from 'classnames';
import * as PropTypes from 'prop-types';

import Icon from '@components/shared/Icon';

const Link = ({
  href,
  as,
  scoll,
  icon,
  bold,
  children,
  className,
  onClick,
  type,
  ...props
}) => {
  const Element = href ? 'a' : 'button';
  const elementType = href ? undefined : type;
  const Component = (
    <Element
      className={classNames(
        'link',
        {
          'link--icon': icon,
          'link--bold': bold,
          'link--btn': !href
        },
        className
      )}
      onClick={onClick || void 0}
      type={elementType}
      {...props}
    >
      {(icon && (
        <>
          <span className="link__text">{children}</span>
          <Icon icon={icon} className="link__icon" />
        </>
      )) ||
        children}
    </Element>
  );

  if (href) {
    return (
      <NextLink href={href} as={as} scroll={scoll}>
        {Component}
      </NextLink>
    );
  }

  return Component;
};

Link.defaultProps = {
  bold: false,
  type: 'button'
};

Link.propTypes = {
  as: PropTypes.string,
  bold: PropTypes.bool,
  scoll: PropTypes.any,
  icon: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
  onClick: PropTypes.func,
  href: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  type: PropTypes.string
};

export default Link;

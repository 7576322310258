import React from 'react';

import {
  GA_ID,
  HOTJAR_ID,
  G_TAG_MANAGER,
  REDDIT_PIXEL_ID
} from '@constants/common';

const googleAnalyticsScript = !GA_ID ? null : (
  <>
    <script
      type="text/javascript"
      dangerouslySetInnerHTML={{
        __html: `
            window.ga=window.ga||function(){(ga.q=ga.q||[]).push(arguments)};ga.l=+new Date;
            ga('create', '${GA_ID}', 'auto');
            ga('send', 'pageview');
        `
      }}
    />
    <script async src="https://www.google-analytics.com/analytics.js" />
  </>
);

const hotjarScript = !HOTJAR_ID ? null : (
  <script
    type="text/javascript"
    dangerouslySetInnerHTML={{
      __html: `
               (function(h,o,t,j,a,r){
                    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                    h._hjSettings={hjid:${HOTJAR_ID},hjsv:6};
                    a=o.getElementsByTagName('head')[0];
                    r=o.createElement('script');r.async=1;
                    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                    a.appendChild(r);
               })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
            `
    }}
  />
);

const gTagManagerScript = !G_TAG_MANAGER ? null : (
  <script
    type="text/javascript"
    dangerouslySetInnerHTML={{
      __html: `
               (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
               })(window,document,'script','dataLayer', '${G_TAG_MANAGER}');
            `
    }}
  />
);

const gTagManagerFrame = !G_TAG_MANAGER ? null : (
  <noscript
    dangerouslySetInnerHTML={{
      __html: `
              <iframe src="https://www.googletagmanager.com/ns.html?id=${G_TAG_MANAGER}" 
                      height="0"
                      width="0" 
                      style="display:none;visibility:hidden">        
              </iframe>
            `
    }}
  />
);

const redditPixelScript = !REDDIT_PIXEL_ID ? null : (
  <script
    type="text/javascript"
    dangerouslySetInnerHTML={{
      __html: `
                !function(w,d){if(!w.rdt){
                    var p=w.rdt=function(){
                        p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};
                        p.callQueue=[];var t=d.createElement("script");
                        t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;
                        var s=d.getElementsByTagName("script")[0];
                        s.parentNode.insertBefore(t,s)
                    }
                }(window,document);
                rdt('init','${REDDIT_PIXEL_ID}', {"optOut":false,"useDecimalCurrencyValues":true,"aaid":"<AAID-HERE>","email":"<EMAIL-HERE>","externalId":"<EXTERNAL-ID-HERE>","idfa":"<IDFA-HERE>"});
                rdt('track', 'PageVisit');
                `
    }}
  />
);

export {
  googleAnalyticsScript,
  hotjarScript,
  gTagManagerScript,
  gTagManagerFrame,
  redditPixelScript
};

import { handleActions } from 'redux-actions';

import types from './types';

const initialState = {
  loading: false,
  details: {
    id: null,
    username: null,
    email: null,
    description: null,
    system_avatar: {},
    disease_conditions: [],
    diets: [],
    supplements: [],
    medicine: [],
    reviews: [],
    recipes_count: 0,
    success_stories_count: 0,
    reviews_count: 0
  }
};

const reducer = {
  [types.FETCH_DETAILS]: state => ({
    ...state,
    loading: true
  }),
  [types.FETCH_DETAILS_SUCCESS]: (state, { payload }) => ({
    ...state,
    details: payload,
    loading: false
  })
};

export const profileReducer = handleActions(reducer, initialState, {
  prefix: types.PREFIX
});

import React from 'react';
import Link from 'next/link';

import routes from '@constants/routes';

const Logo = () => (
  <div className="logo">
    <Link href={routes.home}>
      <a className="logo__link">
        <img src="/images/logo.svg" alt="logo" className="logo__img" />
      </a>
    </Link>
  </div>
);

export default Logo;

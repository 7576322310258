export default {
  PREFIX: 'COMBINATIONS',

  FETCH_COMBINATIONS: 'FETCH_COMBINATIONS',
  FETCH_COMBINATIONS_SUCCESS: 'FETCH_COMBINATIONS_SUCCESS',
  FETCH_COMBINATIONS_FAILED: 'FETCH_COMBINATIONS_FAILED',

  CHANGE_FILTERS: 'CHANGE_FILTERS',
  RESET_FILTERS: 'RESET_FILTERS',

  CHANGE_PAGINATION: 'CHANGE_PAGINATION',
  RESET_PAGINATION: 'RESET_PAGINATION'
};

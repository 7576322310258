import { handleActions } from 'redux-actions';

import types from './types';

const initialState = {
  symptoms: {
    loading: false,
    list: []
  },
  diets: {
    loading: false,
    list: []
  }
};

const reducer = {
  [types.FETCH_DIETS]: state => ({
    ...state,
    diets: {
      ...state.symptoms,
      loading: true
    }
  }),
  [types.FETCH_DIETS_SUCCESS]: (state, { payload }) => ({
    ...state,
    diets: {
      list: payload,
      loading: false
    }
  }),
  [types.FETCH_SYMPTOMS]: state => ({
    ...state,
    symptoms: {
      ...state.symptoms,
      loading: true
    }
  }),
  [types.CLEAR_SYMPTOMS]: state => ({
    ...state,
    symptoms: {
      loading: false,
      list: []
    }
  }),
  [types.FETCH_SYMPTOMS_SUCCESS]: (state, { payload }) => ({
    ...state,
    symptoms: {
      list: payload,
      loading: false
    }
  })
};

export const commonReducer = handleActions(reducer, initialState, {
  prefix: types.PREFIX
});

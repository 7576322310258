import React from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';

const Icon = ({ icon, className }) => (
  <i className={classNames('icon', `icon-${icon}`, className)} />
);

Icon.propTypes = {
  icon: PropTypes.string,
  className: PropTypes.string
};

export default Icon;

import { createActions } from 'redux-actions';

import types from './types';

const identityActions = [
  types.SET_TYPES,
  types.FETCH_CURES,
  types.FETCH_CURES_SUCCESS,
  types.FETCH_CURES_FAILED,
  types.CHANGE_FILTERS,
  types.RESET_FILTERS,
  types.CHANGE_PAGINATION,
  types.RESET_PAGINATION,
  types.FETCH_EFFECTIVE_PERIODS,
  types.FETCH_EFFECTIVE_PERIODS_SUCCESS
];

export const {
  setTypes,
  fetchCures,
  fetchCuresSuccess,
  fetchCuresFailed,
  changeFilters,
  resetFilters,
  changePagination,
  resetPagination,
  fetchEffectivePeriods,
  fetchEffectivePeriodsSuccess
} = createActions({}, ...identityActions, {
  prefix: types.PREFIX
});

import isFunction from 'lodash/isFunction';

import { GA_ID } from '@constants/common';

export default {
  sendEvent(event, action, label) {
    if (GA_ID && isFunction(window.ga)) {
      window.ga('send', 'event', event, action, label);
    }
  },
  sendClickEvent(action, label) {
    this.sendEvent('click', action, label);
  },
  sendSearchEvent(action, label) {
    this.sendEvent('search', action, label);
  },
  sendReviewEvent(action, label) {
    this.sendEvent('review', action, label);
  },
  sendPageChangeEvent(action, label) {
    this.sendEvent('page-change', action, label);
  },
  sendFilterChangeEvent(action, label) {
    this.sendEvent('filter-change', action, label);
  },
  sendPageViewEvent(action, label) {
    this.sendEvent('pageview', action, label);
  }
};

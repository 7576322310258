import React from 'react';
import classNames from 'classnames';
import * as PropTypes from 'prop-types';

const Title = ({
  heading,
  size,
  bold,
  children,
  className,
  center,
  ...props
}) => {
  const Element = heading;

  return (
    <Element
      className={classNames(
        'title',
        {
          [`title--${size}`]: size, // xl, lg, md, sm, xs
          'title--bold': bold,
          'title--center': center
        },
        className
      )}
      {...props}
    >
      {children}
    </Element>
  );
};

Title.defaultProps = {
  heading: 'h1',
  size: 'xl'
};

Title.propTypes = {
  size: PropTypes.oneOf(['xl', 'lg', 'md', 'sm', 'xs']),
  heading: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  bold: PropTypes.bool,
  center: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.any
};

export default Title;

import React from 'react';
import classNames from 'classnames';
import * as PropTypes from 'prop-types';

const Stepper = ({ steps, current }) => {
  const stepsArray = new Array(steps).fill(null);

  return (
    <ul className="stepper">
      {stepsArray.map((step, index) => (
        <li
          key={index}
          className={classNames('stepper__step', {
            'stepper__step--active': index <= current - 1
          })}
        />
      ))}
    </ul>
  );
};

Stepper.propTypes = {
  steps: PropTypes.number,
  current: PropTypes.number
};

export default Stepper;

import { createActions } from 'redux-actions';

import types from './types';

const identityActions = [
  types.SET_USER,
  types.CLEAR_USER,
  types.LOGOUT,
  types.GET_SHORT_PROFILE
];

export const { setUser, clearUser, logout, getShortProfile } = createActions(
  {},
  ...identityActions,
  {
    prefix: types.PREFIX
  }
);

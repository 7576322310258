import { createActions } from 'redux-actions';

import types from './types';

const identityActions = [
  types.SET_CONDITIONS,
  types.SET_CURRENT_CONDITION,
  types.CLEAR_CURRENT_CONDITION
];

export const {
  setConditions,
  setCurrentCondition,
  clearCurrentCondition
} = createActions({}, ...identityActions, {
  prefix: types.PREFIX
});

export default {
  PREFIX: 'COMMON',

  FETCH_DIETS: 'FETCH_DIETS',
  FETCH_DIETS_SUCCESS: 'FETCH_DIETS_SUCCESS',

  FETCH_SYMPTOMS: 'FETCH_SYMPTOMS',
  CLEAR_SYMPTOMS: 'CLEAR_SYMPTOMS',
  FETCH_SYMPTOMS_SUCCESS: 'FETCH_SYMPTOMS_SUCCESS'
};

import { handleActions } from 'redux-actions';

import types from './types';

import AuthSession from '@utils/AuthSession';

const initialState = {
  user: null
};

const reducer = {
  [types.SET_USER]: (state, { payload }) => {
    const newUser = { ...state.user, ...payload };
    AuthSession.setUserData(newUser);

    return {
      ...state,
      user: newUser
    };
  },
  [types.CLEAR_USER]: state => {
    AuthSession.remove();

    return {
      ...state,
      user: null
    };
  }
};

export const authReducer = handleActions(reducer, initialState, {
  prefix: types.PREFIX
});

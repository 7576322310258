import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Thumbnail = ({ src, size, className, style, ...props }) => (
  <div
    role="image"
    aria-label="thumbnail"
    className={classNames(
      'thumbnail',
      { [`thumbnail--${size}`]: size }, // cover, contain
      className
    )}
    style={{ backgroundImage: `url(${src})`, ...style }}
    {...props}
  />
);

Thumbnail.defaultProps = {
  size: 'cover'
};

Thumbnail.propTypes = {
  style: PropTypes.any,
  size: PropTypes.string,
  className: PropTypes.string,
  src: PropTypes.string
};

export default Thumbnail;

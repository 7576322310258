import React from 'react';
import * as PropTypes from 'prop-types';
import get from 'lodash/get';
import pluralize from 'pluralize';
import { Form, Formik } from 'formik';
import { connect } from 'react-redux';

import { AddCureSchema } from './schema';

import { setData, setStep, setReviewOption } from '@store/user-review/actions';
import Title from '@components/shared/Title';
import Error from '@components/shared/Error';
import Button from '@components/shared/Button';
import Stepper from '@components/shared/Stepper';
import FormikField from '@components/shared/FormikField';
import API from '@utils/API';
import Helpers from '@utils/Helpers';
import GuestReviews from '@utils/GuestReviews';
import { apiUrls } from '@constants/api';
import { cureTypes } from '@constants/common';
import { reviewFlows, reviewSteps } from '@constants/review';

const AddCureForm = ({
  user,
  data,
  cureTypesList,
  currentCondition,
  conditionsList,
  setData,
  setStep,
  setReviewOption,
  stepper,
  flowType
}) => {
  const availableCureTypes = cureTypesList
    .filter(i => [cureTypes.product, cureTypes.supplement].includes(i.entry))
    .map(i => ({ ...i, title: pluralize.singular(i.title) }));

  const handleSubmit = async (values, actions) => {
    const formData = {
      ...values,
      type: get(values, 'type.slug'),
      disease_condition: get(values, 'disease_condition.slug')
    };
    try {
      const urls = {
        products: apiUrls.cures.createProduct,
        supplements: apiUrls.cures.createSupplement
      };
      const {
        data: { slug }
      } = await API.post(urls[formData.type], formData);
      setData({
        cure: slug,
        cure_title: values.name,
        condition: values.disease_condition,
        isNewCure: true
      });
      setReviewOption({ reviewIsSkippable: true });
      setStep(reviewSteps.addDescription);
      if (!user) {
        GuestReviews.setProduct(slug);
      }
    } catch (err) {
      actions.setErrors(err);
    }
  };

  return (
    <Formik
      enableReinitialize
      validateOnMount
      validationSchema={AddCureSchema}
      initialValues={{
        name: '',
        type: data.type || availableCureTypes[0],
        disease_condition:
          data.condition || currentCondition || conditionsList[0],
        source: ''
      }}
      onSubmit={handleSubmit}
    >
      {({ values, isValid, isSubmitting, errors }) => (
        <Form
          className="leave-review__form"
          onKeyDown={Helpers.preventKeyEvent}
        >
          <Title className="leave-review__form-title" size="md">
            Adding a {get(values, 'type.entry')} to CureRate
          </Title>
          <Error text={errors.non_field_errors} nonField={true} />
          {(flowType === reviewFlows.writeReview ||
            flowType === reviewFlows.fullProduct) && (
            <>
              <div className="row">
                <div className="col-xxs-12 col-sm-6">
                  <FormikField
                    name="type"
                    type="select"
                    options={availableCureTypes}
                    getOptionLabel={option => option.title}
                    getOptionValue={option => option.slug}
                    instanceId="type"
                    placeholder={false}
                    isClearable={false}
                    visibleIndicators
                    label="Type:"
                  />
                </div>
                <div className="col-xxs-12 col-sm-6">
                  <FormikField
                    name="disease_condition"
                    type="select"
                    options={conditionsList}
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.slug}
                    instanceId="type"
                    placeholder={false}
                    isClearable={false}
                    visibleIndicators
                    label="Condition:"
                  />
                </div>
              </div>
              <div className="leave-review__separator" />
            </>
          )}
          <FormikField
            name="name"
            type="text"
            label={`${Helpers.capitalizeFirstLetter(
              get(values, 'type.entry')
            )} name:`}
            placeholder="Include the brand name"
          />
          <FormikField
            name="source"
            type="text"
            label="Add link where to buy this online:"
            isOptional
            placeholder="https://"
          />
          <div className="leave-review__form-footer">
            <div className="row no-margin ai-middle-xxs jc-between-xxs">
              <Stepper steps={stepper.steps} current={stepper.current} />
              <Button
                className="leave-review__form-add-product-btn"
                disabled={!isValid || isSubmitting}
                type="submit"
                color="yellow"
              >
                Submit for Review
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

AddCureForm.propTypes = {
  user: PropTypes.any,
  data: PropTypes.object,
  conditionsList: PropTypes.array,
  currentCondition: PropTypes.any,
  cureTypesList: PropTypes.array,
  setData: PropTypes.func,
  setStep: PropTypes.func,
  setReviewOption: PropTypes.func,
  stepper: PropTypes.any,
  flowType: PropTypes.string
};

const mapStateToProps = ({
  auth: { user },
  userReview: { data, stepper, flowType },
  conditions: { conditionsList, currentCondition },
  cures: { typesList }
}) => ({
  user,
  data,
  conditionsList,
  currentCondition,
  cureTypesList: typesList,
  stepper,
  flowType
});

export default connect(mapStateToProps, {
  setData,
  setStep,
  setReviewOption
})(AddCureForm);

import React, { useEffect } from 'react';
import get from 'lodash/get';
import classNames from 'classnames';
import Router from 'next/router';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';

import SelectCureForm from './SelectCureForm';
import AddCureForm from './AddCureForm';
import ReviewForm from './ReviewForm';
import AdditionalForm from './AdditionalForm';
import AlternativesForm from './AlternativesForm';
import SuccessBanner from './SuccessBanner';
import GuestBanner from './GuestBanner';
import AddRecipeForm from './AddRecipeForm';

import Loader from '@components/shared/Loader';
import Modal from '@components/shared/Modal';
import { cureTypes } from '@constants/common';
import { reviewFlows, reviewSteps } from '@constants/review';
import Ga from '@utils/Ga';
import gaEvents from '@constants/ga-events';
import {
  resetFlow,
  toggleModal,
  updateStepper
} from '@store/user-review/actions';

const LeaveReviewModal = ({
  data,
  step,
  isOpen,
  toggleModal,
  resetFlow,
  updateStepper,
  flowType,
  successBanner
}) => {
  const handleClose = () => {
    toggleModal();
    resetFlow();

    Ga.sendReviewEvent(gaEvents.closeReviewModal);

    const successModals = [reviewSteps.successImmediate, reviewSteps.success];

    if (successModals.includes(step) && successBanner.redirectUri) {
      const href = get(
        successBanner.redirectUri,
        'href',
        successBanner.redirectUri
      );
      const as = get(successBanner.redirectUri, 'as', undefined);
      Router.push(href, as, {
        shallow: true
      });
    }
  };

  useEffect(() => {
    switch (step) {
      case reviewSteps.addCure:
        if (
          flowType === reviewFlows.product ||
          flowType === reviewFlows.fullProduct
        ) {
          updateStepper({ current: 1, steps: 3 });
          return;
        }
        updateStepper({ current: 2, steps: 4 });
        break;
      case reviewSteps.addDescription:
        if (
          flowType === reviewFlows.product ||
          flowType === reviewFlows.fullProduct
        ) {
          updateStepper({ current: 2 });
          return;
        }
        if (flowType === reviewFlows.review) {
          updateStepper({ current: 1, steps: 2 });
          return;
        }
        if (!data.isNewCure) {
          updateStepper({ current: 2 });
        } else {
          updateStepper({ current: 3 });
        }
        break;
      case reviewSteps.additionalInfo:
      case reviewSteps.recommendations:
        if (!data.isNewCure) {
          updateStepper({ current: 3 });
        } else {
          updateStepper({ current: 4 });
        }
        break;
    }
  }, [step]);

  const isRecipeReview = get(data, 'type.entry') === cureTypes.recipe;

  const renderStep = () => {
    switch (step) {
      case reviewSteps.selectCure:
        return <SelectCureForm />;
      case reviewSteps.addCure:
        return isRecipeReview ? <AddRecipeForm /> : <AddCureForm />;
      case reviewSteps.addDescription:
        return <ReviewForm isRecipeReview={isRecipeReview} />;
      case reviewSteps.additionalInfo:
        return <AdditionalForm />;
      case reviewSteps.recommendations:
        return <AlternativesForm />;
      case reviewSteps.guestBanner:
        return <GuestBanner />;
      case reviewSteps.success:
        return <SuccessBanner />;
      case reviewSteps.successImmediate:
        return <SuccessBanner immediateMessage />;
      default:
        return <Loader />;
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      className={classNames('leave-review', {
        'leave-review__signup-modal': step === reviewSteps.guestBanner
      })}
    >
      <div className="review-modal">{renderStep()}</div>
    </Modal>
  );
};

const mapStateToProps = ({
  userReview: { step, isOpen, data, flowType, successBanner }
}) => ({
  step,
  isOpen,
  flowType,
  data,
  successBanner
});

LeaveReviewModal.propTypes = {
  step: PropTypes.string,
  isOpen: PropTypes.bool,
  data: PropTypes.object,
  toggleModal: PropTypes.func,
  updateStepper: PropTypes.func,
  resetFlow: PropTypes.func,
  flowType: PropTypes.string,
  successBanner: PropTypes.object
};

export default connect(mapStateToProps, {
  toggleModal,
  resetFlow,
  updateStepper
})(LeaveReviewModal);

import { createActions } from 'redux-actions';

import types from './types';

const identityActions = [
  types.FETCH_COMBINATIONS,
  types.FETCH_COMBINATIONS_SUCCESS,
  types.FETCH_COMBINATIONS_FAILED,
  types.CHANGE_FILTERS,
  types.RESET_FILTERS,
  types.CHANGE_PAGINATION,
  types.RESET_PAGINATION
];

export const {
  fetchCombinations,
  fetchCombinationsSuccess,
  fetchCombinationsFailed,
  changeFilters,
  resetFilters,
  changePagination,
  resetPagination
} = createActions({}, ...identityActions, {
  prefix: types.PREFIX
});

import React from 'react';
import Router from 'next/router';
import { connect } from 'react-redux';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import * as PropTypes from 'prop-types';

import { resetFlow } from '@store/user-review/actions';
import Title from '@components/shared/Title';
import Text from '@components/shared/Text';
import Button from '@components/shared/Button';

const SuccessBanner = ({ resetFlow, successBanner, immediateMessage }) => {
  const defaultDescription =
    'Our team will review it soon and notify you once we upload it to CureRate.';

  const handleClick = () => {
    if (successBanner.callback && isFunction(successBanner.callback)) {
      successBanner.callback();
    }

    if (successBanner.redirectUri) {
      const href = get(
        successBanner.redirectUri,
        'href',
        successBanner.redirectUri
      );
      const as = get(successBanner.redirectUri, 'as', undefined);

      Router.push(href, as, {
        shallow: true
      });
    }

    resetFlow();
  };

  return (
    <div className="leave-review__success">
      <img src="/images/success-review.svg" alt="success" />
      <Title className="leave-review__success-title" size="md">
        {successBanner.title}
      </Title>
      {!immediateMessage && (
        <Text className="leave-review__success-text">
          {successBanner.description || defaultDescription}
        </Text>
      )}
      <Button
        color="yellow"
        onClick={handleClick}
        className="leave-review__success-btn"
      >
        Happy to help!
      </Button>
    </div>
  );
};

SuccessBanner.propTypes = {
  resetFlow: PropTypes.func,
  successBanner: PropTypes.object,
  immediateMessage: PropTypes.bool
};

const mapStateToProps = ({ userReview: { successBanner } }) => ({
  successBanner
});

export default connect(mapStateToProps, { resetFlow })(SuccessBanner);

import { takeEvery, select, put } from 'redux-saga/effects';

import types from './types';
import { getUrls, getPagination } from './selects';

import API from '@utils/API';
import {
  fetchCommentsSuccess,
  fetchCommentsFailed
} from '@store/comments/actions';

function* asyncFetchList() {
  const urls = yield select(getUrls);
  const { page, page_size } = yield select(getPagination);

  try {
    const { data } = yield API.get(urls.getList, {
      page,
      page_size
    });
    yield put(fetchCommentsSuccess(data));
  } catch (err) {
    yield put(fetchCommentsFailed(err));
  }
}

const withPrefix = action => `${types.PREFIX}/${action}`;

export const commentsSagas = [
  takeEvery(withPrefix(types.FETCH_COMMENTS), asyncFetchList)
];

import React from 'react';
import { confirmable, createConfirmation } from 'react-confirm';

import ConfirmationDialog from '@components/shared/ConfirmationDialog';

const AsyncConfirmDialog = confirmable(
  ({ show, proceed, confirmation, options }) => (
    <ConfirmationDialog
      onClose={() => proceed(false)}
      onConfirm={() => proceed(true)}
      isOpen={show}
      acceptText={options.acceptText}
      declineText={options.declineText}
      title={confirmation}
      inverted={options.inverted}
    >
      {options.children}
    </ConfirmationDialog>
  )
);

export const confirm = createConfirmation(AsyncConfirmDialog);

export function confirmWrapper(
  confirmation,
  options = {
    acceptText: 'Yes',
    declineText: 'No',
    children: null
  }
) {
  return confirm({ confirmation, options });
}

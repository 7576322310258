import { handleActions } from 'redux-actions';
import cloneDeep from 'lodash/cloneDeep';

import types from './types';

const defaultPageSize = 12;

const initialFilters = {
  page: 1,
  disease_condition: undefined,
  type: undefined,
  tags: undefined,
  ordering: undefined,
  page_size: defaultPageSize
};

const initialData = {
  pages: 1,
  count: 0,
  loading: false,
  results: []
};

const initialState = {
  filters: cloneDeep(initialFilters),
  ...cloneDeep(initialData)
};

const reducer = {
  [types.FETCH_LIST]: state => ({
    ...state,
    loading: true
  }),
  [types.FETCH_LIST_SUCCESS]: (state, { payload }) => ({
    ...state,
    loading: false,
    count: payload.count,
    results: payload.results
  }),
  [types.SET_FILTERS]: (state, { payload }) => ({
    ...state,
    filters: {
      ...state.filters,
      ...payload
    }
  }),
  [types.RESET_FILTERS]: state => ({
    ...state,
    filters: cloneDeep(initialFilters)
  }),
  [types.RESET_DATA]: state => ({
    ...state,
    ...cloneDeep(initialData)
  })
};

export const favoritesReducer = handleActions(reducer, initialState, {
  prefix: types.PREFIX
});

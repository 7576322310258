import React from 'react';
import * as PropTypes from 'prop-types';

import FormikField from '@components/shared/FormikField';

const RatingWithLabels = ({ name, ratingLowCaption, ratingHighCaption }) => (
  <div className="rating-with-labels">
    <div className="row no-margin ai-middle-xxs jc-center-xxs">
      <span className="label">{ratingLowCaption}</span>
      <FormikField iconSize={24} showErrors={false} name={name} type="rating" />
      <span className="label">{ratingHighCaption}</span>
    </div>
  </div>
);

RatingWithLabels.propTypes = {
  name: PropTypes.string.isRequired,
  ratingLowCaption: PropTypes.string.isRequired,
  ratingHighCaption: PropTypes.string.isRequired
};

export default RatingWithLabels;

import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import useViewportSizes from 'use-viewport-sizes';
import classNames from 'classnames';
import { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';

import Icon from '@components/shared/Icon';
import { mdStartBreakpoint } from '@constants/common';

const newOptionPrefix = 'Click to add “';
const newOptionPostfix = '”';

const DropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <Icon icon="chevron-down" />
  </components.DropdownIndicator>
);
const ClearIndicator = props => (
  <components.ClearIndicator {...props}>
    <Icon icon="close" />
  </components.ClearIndicator>
);
const MultiValueRemove = props => (
  <components.MultiValueRemove {...props}>
    <Icon icon="close" />
  </components.MultiValueRemove>
);

const CreatableOption = props => {
  const label = get(props, 'label', '');
  const isNewOption = label.startsWith(newOptionPrefix);

  return isNewOption ? (
    <components.Option className="select__option--is-new" {...props}>
      <Icon icon="plus-slim" />
      <span>{label}</span>
    </components.Option>
  ) : (
    <components.Option {...props} />
  );
};

// eslint-disable-next-line react/display-name
const Select = forwardRef(
  (
    {
      name,
      error,
      value,
      onBlur,
      onChange,
      hideControl,
      onBlurCreate,
      getOptionLabel,
      getOptionValue,
      getNewOptionData,
      formatInputData,
      formatOutputData,
      components,
      placeholder,
      ...props
    },
    ref
  ) => {
    const [viewportWidth] = useViewportSizes(500);

    const getValues = data => {
      if (Array.isArray(data) && formatInputData) {
        return data.map(option => formatInputData(option));
      }

      return data;
    };

    const handleBlur = e => {
      // eslint-disable-next-line react/prop-types
      onBlur && onBlur(e);

      if (onBlurCreate && e.target.value) {
        onChange({
          target: {
            name: name,
            value: handleDuplicateValues(
              getValues([...value, onBlurCreate(e.target.value)])
            )
          }
        });
      }
    };

    const handleChange = data => {
      let value = data;

      if (Array.isArray(data)) {
        value = handleDuplicateValues(data);

        if (formatOutputData) {
          value = value.map(option => formatOutputData(option));
        }
      }

      if (!value && props.isMulti) {
        value = [];
      }

      onChange({
        target: {
          name: name,
          value: value
        }
      });
    };

    const handleDuplicateValues = data =>
      data.reduce((total, current) => {
        if (!total.find(i => getOptionValue(i) === getOptionValue(current))) {
          return total.concat([current]);
        }
        return total;
      }, []);

    const optionsAmount = get(props, 'options.length', 0);
    // Mobile placeholder for creatable selects
    let mobilePlaceholder =
      !optionsAmount && viewportWidth < mdStartBreakpoint
        ? 'Type something and tap to add it'
        : undefined;

    // If mobile - show mobile placeholder, if passed custom placeholder - show it or use default
    // Priority: mobile, custom, default
    const placeholderValue = mobilePlaceholder || placeholder;

    return (
      <div
        className={classNames('select', {
          'select--error': error
        })}
      >
        <CreatableSelect
          ref={ref}
          name={name}
          isClearable={true}
          classNamePrefix="select"
          className="select__select"
          value={getValues(value)}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          getNewOptionData={getNewOptionData}
          onBlur={handleBlur}
          onChange={handleChange}
          noOptionsMessage={() => null}
          createOptionPosition="first"
          components={{
            DropdownIndicator: !hideControl ? DropdownIndicator : null,
            ClearIndicator,
            MultiValueRemove,
            Option: CreatableOption,
            ...components
          }}
          {...props}
          placeholder={placeholderValue}
          formatCreateLabel={userInput =>
            newOptionPrefix + userInput + newOptionPostfix
          }
        />
      </div>
    );
  }
);

Select.defaultProps = {
  isCreatable: false,
  hideControl: false,
  getOptionLabel: option => option.label,
  getOptionValue: option => option.value
};

Select.propTypes = {
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  isCreatable: PropTypes.bool,
  hideControl: PropTypes.bool,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  isMulti: PropTypes.bool,
  options: PropTypes.array,
  onBlurCreate: PropTypes.func,
  formatInputData: PropTypes.func,
  formatOutputData: PropTypes.func,
  getNewOptionData: PropTypes.func,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.object
  ]),
  components: PropTypes.object,
  placeholder: PropTypes.string
};
export default Select;

import React from 'react';
import classNames from 'classnames';
import * as PropTypes from 'prop-types';

const LoaderWrapper = ({ isLoading, children, className }) => (
  <div
    className={classNames(
      'loader-wrapper',
      { 'loader-wrapper--loading': isLoading },
      className
    )}
  >
    <div className="loader-wrapper__content">{children}</div>
    <div className="loader-wrapper__loader" />
  </div>
);

LoaderWrapper.propTypes = {
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.any
};

export default LoaderWrapper;

import React, { useEffect } from 'react';
import get from 'lodash/get';
import { Form, Formik } from 'formik';
import Router from 'next/router';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { RecipeSchema } from './schema';

import { fetchDiets } from '@store/common/actions';
import Helpers from '@utils/Helpers';
import Icon from '@components/shared/Icon';
import Title from '@components/shared/Title';
import Error from '@components/shared/Error';
import Button from '@components/shared/Button';
import FileInput from '@components/shared/Form/File';
import FormikField from '@components/shared/FormikField';
import EditFormSelect from '@components/Profile/EditForm/EditFormSelect';
import { cureTypes } from '@constants/common';
import { apiUrls } from '@constants/api';
import Link from '@components/shared/Link';

const RecipeForm = ({
  classNamePrefix = 'recipe-form',
  formTitle,
  initialValues,
  currentCondition,
  conditionsList,
  diets,
  fetchDiets,
  onSubmit,
  titleSize = 'md',
  cancelButton,
  submitBtnText = 'Submit'
}) => {
  useEffect(() => {
    fetchDiets();
  }, []);

  const handleSubmit = (values, actions) => {
    const formData = {
      ...values,
      images: values.images.map(i => i.id),
      disease_condition: get(values, 'disease_condition.slug')
    };
    onSubmit(formData, actions);
  };

  return (
    <Formik
      enableReinitialize
      validateOnMount
      validationSchema={RecipeSchema}
      initialValues={{
        name: initialValues.name || '',
        diets: initialValues.diets || [],
        ingredients: initialValues.ingredients || [],
        directions: initialValues.directions || [],
        disease_condition:
          initialValues.condition || currentCondition || conditionsList[0],
        additional_info: initialValues.additional_info || '',
        images: initialValues.images || [],
        type: cureTypes.recipe
      }}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue, isValid, isSubmitting, errors }) => (
        <Form
          className={`${classNamePrefix}`}
          onKeyDown={Helpers.preventKeyEvent}
        >
          <Title className={`${classNamePrefix}-title`} size={titleSize}>
            {formTitle}
          </Title>
          <Error text={errors.non_field_errors} nonField={true} />
          <FormikField
            name="name"
            type="text"
            label="Recipe name:"
            placeholder="Enter the name of the recipe"
          />
          {/* Diets */}
          <EditFormSelect
            label="Diet:"
            name="diets"
            placeholder="Select a diet or type anything and press enter"
            instanceId="diets"
            options={diets.list}
            isLoading={diets.loading}
          />
          <FileInput
            initialValue={values.images}
            className="leave-review__files"
            uploadUrl={apiUrls.files.curePhotos}
            placeholder={
              <>
                <div className="leave-review__files-title">
                  <div className="row ai-middle-xxs jc-center-xxs">
                    Add photos <Icon icon="plus-slim" />
                  </div>
                </div>
                <div>Up to 5 photos, max 3mb</div>
              </>
            }
            multiple
            onChange={files => setFieldValue('images', files)}
            showPreviews
            maxSize={3}
          />
          <FormikField
            name="ingredients"
            type="textarea"
            rows={4}
            label="Ingredients:"
            placeholder="Specify ingredients"
          />
          <FormikField
            name="directions"
            type="textarea"
            rows={4}
            label="Directions:"
            placeholder="Add directions"
          />
          <FormikField
            name="additional_info"
            type="textarea"
            rows={4}
            label="Description:"
            isOptional
            placeholder="Add a description about this recipe"
          />
          <div className={`${classNamePrefix}-footer`}>
            <div className="row no-margin ai-middle-xxs jc-end-xxs">
              {cancelButton && (
                <Link
                  className={`${classNamePrefix}__cancel-btn`}
                  bold
                  onClick={Router.back}
                >
                  Cancel
                </Link>
              )}
              <Button
                className={`${classNamePrefix}-add-product-btn`}
                disabled={!isValid || isSubmitting}
                type="submit"
                color="yellow"
              >
                {submitBtnText}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

RecipeForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  fetchDiets: PropTypes.func,
  conditionsList: PropTypes.array,
  currentCondition: PropTypes.any,
  cureTypesList: PropTypes.array,
  diets: PropTypes.object,
  cancelButton: PropTypes.bool,
  classNamePrefix: PropTypes.string,
  formTitle: PropTypes.string,
  titleSize: PropTypes.string,
  submitBtnText: PropTypes.string
};

const mapStateToProps = ({
  common: { diets },
  conditions: { conditionsList, currentCondition },
  cures: { typesList }
}) => ({
  conditionsList,
  currentCondition,
  cureTypesList: typesList,
  diets
});

export default connect(mapStateToProps, {
  fetchDiets
})(RecipeForm);

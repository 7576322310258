export const USER_SESSION_TOKEN_KEY = '__token';
export const USER_DATA_TOKEN_KEY = '__user';

export const FACEBOOK_APP_ID = process.env.NEXT_PUBLIC_FACEBOOK_APP_ID || '';
export const GOOGLE_CLIENT_ID = process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID || '';
export const GA_ID = process.env.NEXT_PUBLIC_GA_ID || null;
export const HOTJAR_ID = process.env.NEXT_PUBLIC_HOTJAR_ID || null;
export const G_TAG_MANAGER = process.env.NEXT_PUBLIC_G_TAG_MANAGER || null;
export const REDDIT_PIXEL_ID = process.env.NEXT_PUBLIC_REDDIT_PIXEL_ID || null;

export const FIREBASE_CONFIG = {
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_FCM_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY
};

export const cureTypes = {
  supplement: 'supplement',
  product: 'product',
  recipe: 'recipe',
  combination: 'combination'
};

export const cureListTypes = {
  handpicked: 'handpicked',
  supplements: 'supplements',
  products: 'products',
  recipes: 'recipes'
};

export const cureStatuses = {
  brokenLink: 'broken_link',
  available: 'available',
  notAvailable: 'not_available'
};

export const cureCardsTypes = {
  vertical: 'vertical',
  horizontal: 'horizontal'
};

export const dateFormats = {
  SHORT: 'MM.DD.YYYY',
  BLOG_ARTICLE: 'MMM D, YYYY'
};

export const contentTypes = {
  comment: 'comment',
  review: 'curereview',
  successStory: 'curesuccesscombination'
};

export const tooltips = {
  addToFavorites: 'Add to favorites',
  removeFromFavorites: 'Remove from favorites'
};

// xs, md breakpoint in pixels from public/styles/_variables.scss
export const smStartBreakpoint = 576;
export const mdStartBreakpoint = 769;
export const lgStartBreakpoint = 993;

export const maxProfileOptionLength = 50;

export const ratingCaptions = {
  common: {
    low: 'Harmful',
    high: 'Super Helpful'
  },
  recipe: {
    low: "Couldn't eat it",
    high: 'Loved it!'
  }
};

export const curesListSorting = [
  { label: 'Recently reviewed', value: 'recently_reviewed' },
  { label: 'Most rated', value: 'most_rated' }
];

export const conditionSlugs = {
  ibs: 'ibs',
  psoriasis: 'psoriasis',
  'crohn-s': 'crohn-s',
  pcos: 'pcos',
  'ulcerative-colitis': 'ulcerative-colitis'
};

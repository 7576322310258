export default {
  PREFIX: 'NOTIFICATIONS',

  SET_FILTERS: 'SET_FILTERS',
  RESET_FILTERS: 'RESET_FILTERS',

  FETCH_LIST: 'FETCH_LIST',
  FETCH_LIST_SUCCESS: 'FETCH_LIST_SUCCESS',

  MARK_AS_REVIEWED: 'MARK_AS_REVIEWED',
  MARK_AS_REVIEWED_SUCCESS: 'MARK_AS_REVIEWED_SUCCESS',

  PUSH_RECEIVED: 'PUSH_RECEIVED'
};

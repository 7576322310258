import { useEffect } from 'react';
import { connect } from 'react-redux';
import Router from 'next/router';
import get from 'lodash/get';
import * as PropTypes from 'prop-types';

import { apiUrls } from '@constants/api';
import { getShortProfile } from '@store/auth/actions';
import { FIREBASE_CONFIG } from '@constants/common';
import Logger from '@utils/Logger';
import API from '@utils/API';

let firebase = null;

const FirebaseProvider = ({ userId, getShortProfile }) => {
  useEffect(() => {
    firebase = require('firebase/app');
    require('firebase/firebase-app');
    require('firebase/firebase-messaging');

    if (!userId) {
      firebase.apps.forEach(app => {
        app.delete();
      });
      return;
    }

    if (!notificationsIsSupported) {
      Logger.error('This browser does not support notification');
      return;
    }

    if (!firebase.apps.length) {
      initialize();
    }
  }, [userId]);

  const initialize = () => {
    try {
      firebase.initializeApp(FIREBASE_CONFIG);
      const messaging = firebase.messaging();

      Notification.requestPermission().then(async result => {
        if (result === 'granted') {
          const token = await messaging.getToken();
          Logger.log('Token received:', token);

          await setDeviceToken(token);

          messaging.onMessage(onMessageHandler);
          messaging.onTokenRefresh(() => onTokenRefresh(messaging));
        }
      });
    } catch (err) {
      Logger.error(err);
    }
  };

  const setDeviceToken = async key => {
    try {
      await API.post(apiUrls.notifications.deviceTokens, { key });
    } catch (e) {
      Logger.error(e);
    }
  };

  const onMessageHandler = payload => {
    //eslint-disable-next-line
    console.log('push notification', payload)
    const { data } = payload;

    const notificationOptions = {
      body: data.body,
      icon: data.icon
    };
    const message = new Notification(data.title, notificationOptions);
    message.onclick = function() {
      let pathname = null;

      try {
        const url = new URL(data.click_action);
        pathname = url.pathname;
      } catch (e) {
        Logger.error(e);
      }

      if (pathname) {
        Router.push(pathname);
      }
    };
    getShortProfile();
  };

  const onTokenRefresh = async messaging => {
    try {
      const refreshedToken = await messaging.getToken();
      await setDeviceToken(refreshedToken);
      Logger.log('Token refreshed.', refreshedToken);
    } catch (err) {
      Logger.error('Unable to retrieve refreshed token ', err);
    }
  };

  const notificationsIsSupported = () => 'Notification' in window;

  return null;
};

FirebaseProvider.propTypes = {
  userId: PropTypes.any,
  getShortProfile: PropTypes.func
};

const mapStateToProps = ({ auth: { user } }) => ({
  userId: get(user, 'id')
});

export default connect(mapStateToProps, { getShortProfile })(FirebaseProvider);

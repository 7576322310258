import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ReactModal from 'react-modal';

import Icon from '@components/shared/Icon';

const Modal = ({
  isOpen,
  onClose,
  closeIcon,
  children,
  className,
  ...props
}) => (
  <ReactModal
    isOpen={isOpen}
    portalClassName="modal"
    bodyOpenClassName="modal-open"
    className={classnames('modal__content', className)}
    overlayClassName="modal__overlay"
    {...props}
  >
    {closeIcon && (
      <button className="modal__close" onClick={onClose}>
        <Icon icon="close" />
      </button>
    )}

    <div className="modal__body">{children}</div>
  </ReactModal>
);

Modal.defaultProps = {
  closeIcon: true
};

Modal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.any,
  closeIcon: PropTypes.bool,
  className: PropTypes.string
};

export default Modal;

import React from 'react';
import withRedux from 'next-redux-wrapper';
import withReduxSaga from 'next-redux-saga';
import { Provider } from 'react-redux';
import Head from 'next/head';
import Modal from 'react-modal';
import Router, { useRouter } from 'next/router';
import get from 'lodash/get';
import smoothscroll from 'smoothscroll-polyfill';
import * as PropTypes from 'prop-types';
import { GoogleOAuthProvider } from '@react-oauth/google';

import AuthSession from '../utils/AuthSession';
import makeStore from '../store';
import CommonLayout from '../layouts/common';

import API from '@utils/API';
import Logger from '@utils/Logger';
import { openModal } from '@store/ui/actions';
import { setUser } from '@store/auth/actions';
import { fetchEffectivePeriods, setTypes } from '@store/cures/actions';
import { setConditions } from '@store/conditions/actions';
import { claimData } from '@store/user-review/actions';
import { apiUrls } from '@constants/api';
import modals from '@constants/modals';
import useIsomorphicLayoutEffect from '@hooks/useIsomorphicEffect';
import FirebaseProvider from '@components/FirebaseProvider';
import Helpers from '@utils/Helpers';
import GuestReviews from '@utils/GuestReviews';
import Ga from '@utils/Ga';
import {
  googleAnalyticsScript,
  gTagManagerFrame,
  gTagManagerScript,
  hotjarScript,
  redditPixelScript
} from '@constants/scripts';
import routes from '@constants/routes';
import { GOOGLE_CLIENT_ID } from '@constants/common';

import '../public/styles/main.scss';

/*
  Redefine useLayoutEffect with useIsomorphicLayoutEffect - for prevent useLayoutEffect calls on the server.
*/
React.useLayoutEffect = useIsomorphicLayoutEffect;

let storeInstance = null;

/* eslint-disable no-underscore-dangle, react/no-danger */
const MyApp = ({ Component, pageProps, store, err }) => {
  storeInstance = store;
  AuthSession.setHeader();

  const router = useRouter();

  const initUserFlow = user => {
    if (router.asPath === routes.survey.profile) {
      return;
    }

    const userGuestReviewObj = GuestReviews.getReview(); // Can be null

    const userGuestReview = get(userGuestReviewObj, 'review');
    const userGuestProduct = GuestReviews.getProduct();
    const userGuestStory = GuestReviews.getSuccessStory();
    const userGuestRecipe = GuestReviews.getRecipe();

    if (!user.profile_avatar) {
      store.dispatch(openModal(modals.SETUP_MODAL));
    } else if (
      userGuestReview ||
      userGuestProduct ||
      userGuestStory ||
      userGuestRecipe
    ) {
      store.dispatch(
        claimData({
          userGuestReviewObj,
          userGuestReview,
          userGuestProduct,
          userGuestStory,
          userGuestRecipe
        })
      );
    }
  };

  const getUser = () => {
    const userData = AuthSession.getUserData();
    if (userData) {
      return JSON.parse(userData);
    }
    return null;
  };

  React.useEffect(() => {
    // Temporary fix issue with scroll restoration disabling - https://github.com/vercel/next.js/issues/3303
    window.history.scrollRestoration = 'manual';

    smoothscroll.polyfill();
    Modal.setAppElement('#__next');
    Router.events.on('routeChangeComplete', (...args) => {
      Ga.sendPageViewEvent(...args);
    });
    const user = getUser();
    if (user) {
      initUserFlow(user);
      store.dispatch(setUser(user));
    }
  }, []);

  React.useEffect(() => {
    const user = getUser();
    if (user) {
      window.scrollTo(0, 0);
      initUserFlow(user);
    }
  }, [router.asPath]);

  const LayoutComponent = pageProps.disableLayout ? 'div' : CommonLayout;

  return (
    <Provider store={store}>
      <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
        <Head>
          <title>{pageProps.__meta__.title}</title>
          {Object.keys(pageProps.__meta__.tags).map(key => (
            <meta
              key={key}
              name={key}
              content={get(pageProps, ['__meta__', 'tags', key])}
            />
          ))}
          <link rel="icon" type="image/png" href="/favicon.png" />
          {googleAnalyticsScript}
          {hotjarScript}
          {gTagManagerScript}
          {redditPixelScript}
        </Head>
        {gTagManagerFrame}
        <FirebaseProvider />
        <LayoutComponent props={pageProps} err={err}>
          <Component {...pageProps} err={err} />
        </LayoutComponent>
      </GoogleOAuthProvider>
    </Provider>
  );
};
/* eslint-enable no-underscore-dangle */

MyApp.getInitialProps = async ({ Component, ctx }) => {
  AuthSession.setHeader(ctx);

  const defaultMetaData = {
    title: 'CureRate',
    tags: {
      description:
        'The first-ever recommendation platform focused on people with chronic conditions. Products, supplements, and recipes rated & reviewed by people who share your challenges.'
    }
  };

  let pageProps = {
    route: {
      pathname: ctx.asPath,
      query: ctx.query
    },
    __meta__: defaultMetaData
  };

  if (ctx.store) {
    const userData = AuthSession.getUserData(ctx);
    if (userData) {
      ctx.store.dispatch(setUser(JSON.parse(userData)));
    }

    try {
      const initialQueries = [
        API.get(apiUrls.conditions.list),
        API.get(apiUrls.cures.types)
      ];
      if (userData) {
        initialQueries.push(API.get(apiUrls.profile.short));
      }

      const [conditionsRes, cureTypesRes, profileRes] = await Promise.all(
        initialQueries
      );

      ctx.store.dispatch(setConditions(conditionsRes.data));
      ctx.store.dispatch(fetchEffectivePeriods());
      ctx.store.dispatch(setTypes(cureTypesRes.data));

      if (userData) {
        ctx.store.dispatch(setUser(profileRes.data));
      }
    } catch (err) {
      Logger.error(err);
      Helpers.serverErrorHandler(err, ctx);
    }
  }

  if (Component.getInitialProps) {
    const componentProps = (await Component.getInitialProps(ctx)) || {};
    pageProps = {
      ...pageProps,
      ...componentProps,
      __meta__: {
        ...defaultMetaData,
        ...(componentProps.__meta__ || {})
      }
    };
  }

  return { pageProps };
};

MyApp.propTypes = {
  Component: PropTypes.any,
  pageProps: PropTypes.any,
  store: PropTypes.any,
  err: PropTypes.any
};

export { storeInstance };

export default withRedux(makeStore)(withReduxSaga(MyApp));

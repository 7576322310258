import React from 'react';
import classNames from 'classnames';
import * as PropTypes from 'prop-types';

const Error = ({ text, nonField, className }) => {
  if (text) {
    return (
      <div
        className={classNames(
          'error',
          {
            'error--non-field': nonField
          },
          className
        )}
      >
        {text}
      </div>
    );
  }

  return null;
};

Error.propTypes = {
  text: PropTypes.string,
  nonField: PropTypes.bool,
  className: PropTypes.string
};

export default Error;

import React, { useEffect, useState } from 'react';
import Router from 'next/router';
import get from 'lodash/get';
import PropTypes from 'prop-types';

import Logo from '@components/shared/Logo';
import Link from '@components/shared/Link';
import Avatar from '@components/shared/Avatar';
import Button from '@components/shared/Button';
import BubbleSelect from '@components/shared/BubbleSelect';
import routes from '@constants/routes';
import Helpers from '@utils/Helpers';
import Hotjar from '@utils/Hotjar';
import hjEvents from '@constants/hj-events';

const DesktopHeader = ({
  route,
  user,
  logout,
  conditionOptions,
  currentCondition,
  headerConfig,
  toggleReviewModal
}) => {
  const [redirectIndicator, setRedirectIndicator] = useState(false);

  const handleChangeCondition = condition => {
    if (condition && condition.slug) {
      Router.push(
        routes.conditions.details.pattern,
        routes.conditions.details.path(condition.slug)
      );
      setRedirectIndicator(true);
    } else {
      Router.push(routes.contactUs);
    }
  };

  useEffect(() => {
    setRedirectIndicator(false);
  }, [currentCondition]);

  const favoritesCounter = get(user, 'favourites_count');
  const notificationsCounter = get(user, 'notifications_count');

  return (
    <div className="header-desktop">
      <div className="container">
        <div className="header-desktop__content">
          <div className="header-desktop__logo">
            <Logo />
          </div>
          {headerConfig.visibility.conditions && (
            <div className="header-desktop__conditions">
              <BubbleSelect
                bold={true}
                color="yellow"
                isLoading={redirectIndicator}
                isDisabled={redirectIndicator}
                options={conditionOptions}
                value={currentCondition}
                onChange={handleChangeCondition}
                instanceId="header-conditions"
                placeholder="Conditions"
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
              />
            </div>
          )}
          <div className="header-desktop__actions">
            {(user && (
              <>
                {headerConfig.visibility.reviewButton && (
                  <Button
                    type="button"
                    className="header-desktop__review"
                    onClick={toggleReviewModal}
                  >
                    Write a review
                  </Button>
                )}
                <div className="header-desktop__menu">
                  <div className="header-desktop__avatar">
                    <Avatar
                      src={user.profile_avatar}
                      alt={user.username}
                      className="header-desktop__avatar-img"
                    />
                    {!!notificationsCounter && (
                      <span className="header-desktop__avatar-notifications">
                        {Helpers.counterLimiter(notificationsCounter, 99)}
                      </span>
                    )}
                  </div>
                  <div className="header-desktop__dropdown">
                    <ul className="header-desktop__list">
                      {user.profile_avatar && (
                        <>
                          <li>
                            <Link
                              href={routes.profile.my}
                              className="header-desktop__link"
                            >
                              My profile
                            </Link>
                          </li>
                          <li>
                            <Link
                              href={routes.profile.favorites}
                              className="header-desktop__link"
                            >
                              Favorites
                              {!!favoritesCounter && (
                                <span className="header-desktop__favorites">
                                  {Helpers.counterLimiter(favoritesCounter)}
                                </span>
                              )}
                            </Link>
                          </li>
                          <li>
                            <Link
                              href={routes.notifications.list}
                              className="header-desktop__link"
                            >
                              Notifications
                              {!!notificationsCounter && (
                                <span className="header-desktop__notifications">
                                  {Helpers.counterLimiter(
                                    notificationsCounter,
                                    99
                                  )}
                                </span>
                              )}
                            </Link>
                          </li>
                          <li>
                            <Link
                              href={routes.profile.settings}
                              className="header-desktop__link"
                            >
                              Settings
                            </Link>
                          </li>
                        </>
                      )}
                      <li>
                        <button
                          type="button"
                          onClick={logout}
                          className="header-desktop__log-out"
                        >
                          Log out
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </>
            )) || (
              <>
                {headerConfig.visibility.signIn && (
                  <Link
                    href={Helpers.authLinkRedirectWrapper(
                      routes.auth.signIn,
                      route
                    )}
                    bold={true}
                  >
                    Sign In
                  </Link>
                )}
                {headerConfig.visibility.signUp && (
                  <Link
                    href={Helpers.authLinkRedirectWrapper(
                      routes.auth.signUp,
                      route
                    )}
                    bold={true}
                  >
                    Sign Up
                  </Link>
                )}
                {headerConfig.visibility.createAccount && (
                  <Button
                    href={Helpers.authLinkRedirectWrapper(
                      routes.auth.signUp,
                      route
                    )}
                    onClick={() => Hotjar.vpv(hjEvents.createAccountHeader)}
                    className="header-desktop__create"
                  >
                    Create Account
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

DesktopHeader.propTypes = {
  user: PropTypes.any,
  route: PropTypes.object,
  logout: PropTypes.func,
  toggleReviewModal: PropTypes.func,
  conditionOptions: PropTypes.array,
  currentCondition: PropTypes.object,
  headerConfig: PropTypes.object
};

export default DesktopHeader;

import React, { useEffect } from 'react';
import { Form, Formik } from 'formik';
import get from 'lodash/get';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { SetupProfileFormSchema } from './schema';

import { fetchDetails as fetchProfileDetails } from '@store/profile/actions';
import Error from '@components/shared/Error';
import SelectAvatar from '@components/shared/Form/SelectAvatar';
import FormikField from '@components/shared/FormikField';
import Button from '@components/shared/Button';
import API from '@utils/API';
import { apiUrls } from '@constants/api';
import Hotjar from '@utils/Hotjar';
import hjEvents from '@constants/hj-events';
import Logger from '@utils/Logger';
import { setUser } from '@store/auth/actions';

const SetupProfileForm = ({
  user,
  conditionsList,
  setUser,
  dataChanged,
  defaultAvatar,
  successCallback,
  fetchProfileDetails,
  profileDetails,
  submitBtnText
}) => {
  useEffect(() => {
    fetchProfileDetails({ id: user.id });
  }, []);

  const userEmail = get(user, 'email');

  const schema = SetupProfileFormSchema(userEmail);

  const initialAvatar = get(profileDetails.system_avatar, 'id', undefined);

  let initialValues = {
    username: initialAvatar ? profileDetails.username : '',
    email: userEmail,
    system_avatar: initialAvatar,
    disease_conditions: profileDetails.disease_conditions
  };

  const handleSubmit = async (values, actions) => {
    const conditions = get(values, 'disease_conditions', []);

    try {
      const isFirstPatch = profileDetails.system_avatar ? 'false' : 'true';
      const {
        data: { username, system_avatar }
      } = await API.patch(`${apiUrls.profile.my}?initial=${isFirstPatch}`, {
        ...values,
        disease_conditions: conditions.map(condition => condition.id)
      });

      Hotjar.vpv(hjEvents.registrationFinished);

      setUser({
        username,
        profile_avatar: system_avatar.file
      });

      successCallback && successCallback({ condition: conditions[0].slug });
    } catch (err) {
      Logger.error(err);
      actions.setErrors(err);
    }
  };

  return (
    <Formik
      validateOnMount
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validationSchema={schema}
    >
      {({ values, touched, errors, setFieldValue, isSubmitting }) => (
        <Form>
          <Error text={errors.non_field_errors} nonField={true} />

          <SelectAvatar
            label="Choose an avatar"
            defaultAvatar={defaultAvatar}
            selected={values.system_avatar}
            error={touched.system_avatar && errors.system_avatar}
            onChange={value => {
              setFieldValue('system_avatar', value);
              dataChanged({
                target: {
                  value,
                  name: 'system_avatar'
                }
              });
            }}
          />

          <FormikField
            type="select"
            label="Conditions"
            isMulti={true}
            options={conditionsList}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            name="disease_conditions"
            onChange={e => {
              dataChanged(e);
            }}
            className="setup-profile-modal__conditions"
            placeholder="Conditions"
            instanceId="setup-profile-modal-conditions"
          />

          <FormikField
            type="text"
            label="Username"
            name="username"
            onChange={e => {
              dataChanged(e);
            }}
            placeholder="Type your username and press enter"
          />

          {!userEmail && (
            <FormikField
              type="email"
              label="Email"
              name="email"
              onChange={e => {
                dataChanged(e);
              }}
              placeholder="name@mail.com"
            />
          )}

          <div className="setup-profile__controls">
            <Button
              type="submit"
              color="yellow"
              loader={isSubmitting}
              disabled={isSubmitting}
            >
              {submitBtnText}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

SetupProfileForm.defaultProps = {
  dataChanged: () => null,
  submitBtnText: 'Done!'
};

SetupProfileForm.propTypes = {
  submitBtnText: PropTypes.string,
  user: PropTypes.any,
  conditionsList: PropTypes.array,
  setUser: PropTypes.func,
  dataChanged: PropTypes.func,
  successCallback: PropTypes.func,
  fetchProfileDetails: PropTypes.func,
  defaultAvatar: PropTypes.bool,
  profileDetails: PropTypes.object
};

const mapStateToProps = ({
  auth: { user },
  profile: { details: profileDetails },
  conditions: { conditionsList }
}) => ({
  user,
  conditionsList,
  profileDetails
});

export default connect(mapStateToProps, { setUser, fetchProfileDetails })(
  SetupProfileForm
);

import { handleActions } from 'redux-actions';
import { clone } from 'lodash';

import types from './types';

import { curesListSorting } from '@constants/common';

const initialFilters = {
  ordering: curesListSorting[1].value, // most_rated
  categories: [],
  tags: [],
  diet: [],
  is_curerate_choice: undefined
};

const initialPagination = {
  page: 1,
  count: null,
  page_size: 12
};

const initialState = {
  typesList: [],
  curesList: [],
  effectivePeriods: [],
  curesErrors: {},
  curesLoading: false,
  filters: clone(initialFilters),
  pagination: clone(initialPagination)
};

const reducer = {
  [types.SET_TYPES]: (state, { payload }) => ({
    ...state,
    typesList: payload.reverse()
  }),
  [types.FETCH_CURES]: state => ({
    ...state,
    curesLoading: true
  }),
  [types.FETCH_CURES_SUCCESS]: (state, { payload }) => ({
    ...state,
    curesList: payload.results,
    curesLoading: false,
    pagination: {
      ...state.pagination,
      count: payload.count
    }
  }),
  [types.FETCH_CURES_FAILED]: (state, { payload }) => ({
    ...state,
    curesErrors: payload,
    curesLoading: false
  }),
  [types.CHANGE_FILTERS]: (state, { payload }) => ({
    ...state,
    filters: {
      ...state.filters,
      ...payload
    }
  }),
  [types.RESET_FILTERS]: state => ({
    ...state,
    filters: clone(initialFilters)
  }),
  [types.CHANGE_PAGINATION]: (state, { payload }) => ({
    ...state,
    pagination: {
      ...state.pagination,
      ...payload
    }
  }),
  [types.RESET_PAGINATION]: state => ({
    ...state,
    pagination: clone(initialPagination)
  }),
  [types.FETCH_EFFECTIVE_PERIODS_SUCCESS]: (state, { payload }) => ({
    ...state,
    effectivePeriods: payload
  })
};

export const curesReducer = handleActions(reducer, initialState, {
  prefix: types.PREFIX
});

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Input = ({
  type,
  name,
  icon,
  error = false,
  value = '',
  onChange = () => void 0,
  ...props
}) => {
  const [showValue, changeShowStatus] = useState(false);

  const isPasswordInput = type === 'password';
  const passwordInputType = showValue ? 'text' : 'password';

  const toggleValueVisibility = () => {
    changeShowStatus(!showValue);
  };

  return (
    <div
      className={classNames('field', {
        'field--icon': icon,
        'field--error': error,
        [`field--${type}`]: type
      })}
    >
      <div className="field-input">
        <input
          id={name}
          name={name}
          className="field-input__field"
          type={isPasswordInput ? passwordInputType : type}
          value={value}
          onChange={onChange}
          {...props}
        />
        {isPasswordInput && (
          <button
            type="button"
            tabIndex={-1}
            className="field-input__toggle"
            onClick={toggleValueVisibility}
          >
            {showValue ? (
              <i className="field-input__eye icon icon-eye-open" />
            ) : (
              <i className="field-input__eye icon icon-eye-close" />
            )}
          </button>
        )}
        {icon && <i className={`field-input__icon icon icon-${icon}`} />}
      </div>
    </div>
  );
};

Input.propTypes = {
  value: PropTypes.any,
  error: PropTypes.any,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.string,
  icon: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func
};

export default Input;

import React from 'react';
import classNames from 'classnames';
import * as PropTypes from 'prop-types';
import dynamic from 'next/dynamic';

const StarRatings = dynamic(() => import('react-star-ratings'), {
  ssr: false
});

const Rating = ({
  className,
  name,
  value,
  onChange,
  iconSize,
  numberOfStars
}) => {
  const handleClick = value => {
    if (onChange) {
      onChange({
        target: {
          name,
          value
        }
      });
    }
  };

  const parsedValue = Number(value) || 0;

  return (
    <div className={classNames('rating', className)}>
      <StarRatings
        numberOfStars={numberOfStars}
        starRatedColor="#F7E353"
        starHoverColor="rgba(247, 227, 83, 0.8)"
        starEmptyColor="#D1D3D4"
        starSpacing="2px"
        rating={parsedValue}
        changeRating={onChange ? handleClick : undefined}
        svgIconPath="M8.65375 0L14.5831 5.94958L22.8702 4.46106L19.0159 11.9105L23 19.2942L14.6879 17.9485L8.86333 24L7.58087 15.7191L0 12.0759L7.51708 8.30359L8.65375 0Z"
        svgIconViewBox="0 0 23 24"
        name={name}
        starDimension={iconSize + 'px'}
      />
    </div>
  );
};

Rating.defaultProps = {
  name: 'rating',
  iconSize: 19,
  numberOfStars: 5
};

Rating.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  className: PropTypes.string,
  iconSize: PropTypes.number,
  numberOfStars: PropTypes.number
};

export default Rating;

import React from 'react';
import { components } from 'react-select';

import Icon from '@components/shared/Icon';

const formatOption = ({ name, slug, cure_type }) => ({
  label: name,
  value: slug,
  type: cure_type
});

const addNewOption = {
  label: `Can't find what you're looking for?`,
  options: [{ label: 'Suggest and add it to CureRate', value: null }]
};

// eslint-disable-next-line react/display-name,react/prop-types
const customOptionCreator = onAddClick => ({ children, ...props }) =>
  // eslint-disable-next-line react/prop-types
  props.data.value ? (
    <components.Option {...props}>{children}</components.Option>
  ) : (
    <components.Option
      {...props}
      innerProps={{
        // eslint-disable-next-line react/prop-types
        ...props.innerProps,
        onClick: onAddClick
      }}
    >
      <strong className="leave-review__form-option-add">
        <Icon icon="plus-slim" />
        {children}
      </strong>
    </components.Option>
  );

export { customOptionCreator, formatOption, addNewOption };

import React, { useEffect } from 'react';
import { Form, Formik } from 'formik';
import { connect } from 'react-redux';
import get from 'lodash/get';
import * as PropTypes from 'prop-types';

import { AdditionalInfoSchema } from './schema';

import EditFormSelect from '@components/Profile/EditForm/EditFormSelect';
import { reviewSteps } from '@constants/review';
import { setData, setStep, submitForm } from '@store/user-review/actions';
import Title from '@components/shared/Title';
import Button from '@components/shared/Button';
import FormikField from '@components/shared/FormikField';
import Error from '@components/shared/Error';
import Link from '@components/shared/Link';
import Logger from '@utils/Logger';
import Stepper from '@components/shared/Stepper';
import Helpers from '@utils/Helpers';
import { fetchSymptoms } from '@store/common/actions';

const AdditionalForm = ({
  data,
  setData,
  submitForm,
  effectivePeriods,
  setStep,
  stepper,
  fetchSymptoms,
  symptoms
}) => {
  useEffect(() => {
    fetchSymptoms({
      disease_condition: get(data, 'condition.slug')
    });
  }, []);

  const handleSubmit = (values, actions) => {
    actions.setSubmitting(true);
    try {
      setData({
        ...values,
        cure_effect_period: get(values, 'cure_effect_period.id')
      });
      submitForm({
        onFailed: err => {
          actions.setErrors(err);
          actions.setSubmitting(false);
        }
      });
    } catch (e) {
      actions.setSubmitting(false);
      Logger.error(e);
    }
  };

  const handleBackClick = () => {
    setStep(reviewSteps.addDescription);
  };

  return (
    <Formik
      enableReinitialize
      validateOnMount
      validationSchema={AdditionalInfoSchema}
      initialValues={{
        cure_effect_period: {},
        combinations: [],
        helped_with: []
      }}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, errors }) => (
        <Form
          className="leave-review__form"
          onKeyDown={Helpers.preventKeyEvent}
        >
          <Title className="leave-review__form-title" size="md">
            Additional Info
          </Title>
          <Error text={errors.non_field_errors} nonField={true} />
          <FormikField
            label="How long until it worked?"
            type="select"
            name="cure_effect_period"
            isOptional
            placeholder="Choose time frame"
            instanceId="cure_effect_period"
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            getNewOptionData={(value, label) => label}
            options={effectivePeriods}
            isClearable={false}
            visibleIndicators
          />
          <EditFormSelect
            label="Combined with:"
            type="select"
            name="combinations"
            isOptional
            instanceId="combinations"
            getOptionLabel={option => option}
            getOptionValue={option => option}
            getNewOptionData={(value, label) => label}
            hideControls
            helpText="Ex: any medicine used at the same time"
          />
          <EditFormSelect
            label="Helped with:"
            type="select"
            name="helped_with"
            options={symptoms.list}
            isLoading={symptoms.loading}
            isOptional
            instanceId="helped_with"
            placeholder="Select symptoms or type anything and press enter"
            getOptionLabel={option => option.name}
            getOptionValue={option => option.name}
            getNewOptionData={(value, label) => ({
              id: null,
              name: label
            })}
            hideControls
            helpText="Ex: Itchiness"
          />
          <div className="leave-review__form-footer">
            <div className="row no-margin ai-middle-xxs jc-between-xxs">
              <Stepper steps={stepper.steps} current={stepper.current} />
              <div>
                <Link
                  className="leave-review__back-btn"
                  bold
                  type="button"
                  disabled={isSubmitting}
                  onClick={handleBackClick}
                >
                  Back
                </Link>
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  color="yellow"
                  loader={isSubmitting}
                >
                  Post
                </Button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

AdditionalForm.propTypes = {
  data: PropTypes.object,
  conditionsList: PropTypes.array,
  currentCondition: PropTypes.any,
  cureTypesList: PropTypes.array,
  effectivePeriods: PropTypes.array,
  setData: PropTypes.func,
  setStep: PropTypes.func,
  submitForm: PropTypes.func,
  stepper: PropTypes.any,
  symptoms: PropTypes.object,
  fetchSymptoms: PropTypes.func
};

const mapStateToProps = ({
  userReview: { data, stepper },
  conditions: { conditionsList, currentCondition },
  cures: { typesList, effectivePeriods },
  common: { symptoms }
}) => ({
  data,
  conditionsList,
  currentCondition,
  cureTypesList: typesList,
  effectivePeriods,
  stepper,
  symptoms
});

export default connect(mapStateToProps, {
  setData,
  setStep,
  submitForm,
  fetchSymptoms
})(AdditionalForm);

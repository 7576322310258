import React, { memo } from 'react';
import isEqual from 'lodash/isEqual';
import * as PropTypes from 'prop-types';

import Button from '@components/shared/Button';
import Thumbnail from '@components/shared/Thumbnail';

const Thumbs = ({ list, onRemove }) => {
  if (!list.length) {
    return null;
  }

  return (
    <div className="form-file__thumbs-container">
      <ul className="form-file__thumbs-list">
        {list.map(file => (
          <li className="form-file__thumb" key={file.id}>
            <Button
              className="form-file__thumb-btn"
              icon="close"
              type="button"
              onClick={() => onRemove(file)}
            />
            <Thumbnail className="form-file__thumb--img" src={file.file} />
          </li>
        ))}
      </ul>
    </div>
  );
};

Thumbs.propTypes = {
  list: PropTypes.array,
  onRemove: PropTypes.func
};

export default memo(Thumbs, (prev, next) => {
  const prevFiles = prev.list.map(i => i.path);
  const nextFiles = next.list.map(i => i.path);

  return isEqual(prevFiles, nextFiles);
});

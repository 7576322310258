import { createActions } from 'redux-actions';

import types from './types';

const identityActions = [
  types.TOGGLE_MODAL,
  types.OPEN_MODAL,
  types.CLOSE_MODAL,
  types.CLOSE_ALL_MODALS
];

export const {
  toggleModal,
  openModal,
  closeModal,
  closeAllModals
} = createActions({}, ...identityActions, {
  prefix: types.PREFIX
});

import { createActions } from 'redux-actions';

import types from './types';

const identityActions = [types.FETCH_DETAILS, types.FETCH_DETAILS_SUCCESS];

export const { fetchDetails, fetchDetailsSuccess } = createActions(
  {},
  ...identityActions,
  {
    prefix: types.PREFIX
  }
);

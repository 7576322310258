import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';

import { uiReducer } from './ui';
import { curesReducer } from './cures';
import { commonReducer } from './common';
import { conditionsReducer } from './conditions';
import { combinationsReducer } from './combinations';
import { authReducer } from './auth';

import { authSagas } from '@store/auth/saga';
import { curesSagas } from '@store/cures/saga';
import { commonSagas } from '@store/common/saga';
import { combinationsSagas } from '@store/combinations/saga';
import { profileReducer } from '@store/profile';
import { profileSagas } from '@store/profile/saga';
import { favoritesReducer } from '@store/favorites';
import { favoritesSagas } from '@store/favorites/saga';
import { commentsReducer } from '@store/comments';
import { commentsSagas } from '@store/comments/saga';
import { reportReducer } from '@store/report';
import { reportSagas } from '@store/report/saga';
import { reviewReducer } from '@store/user-review';
import { reviewSagas } from '@store/user-review/saga';
import { notificationReducer } from '@store/notifications';
import { notificationSagas } from '@store/notifications/saga';
import { surveyReducer } from '@store/survey';
import helpers from '@utils/Helpers';

const rootReducer = combineReducers({
  ui: uiReducer,
  auth: authReducer,
  cures: curesReducer,
  common: commonReducer,
  conditions: conditionsReducer,
  combinations: combinationsReducer,
  profile: profileReducer,
  favorites: favoritesReducer,
  report: reportReducer,
  comments: commentsReducer,
  userReview: reviewReducer,
  notifications: notificationReducer,
  survey: surveyReducer
});

function* rootSaga() {
  yield all([
    ...authSagas,
    ...curesSagas,
    ...commonSagas,
    ...combinationsSagas,
    ...profileSagas,
    ...favoritesSagas,
    ...reportSagas,
    ...commentsSagas,
    ...reviewSagas,
    ...notificationSagas
  ]);
}

export default initialState => {
  let store;
  const sagaMiddleware = createSagaMiddleware();
  if (helpers.isClient()) {
    store = createStore(
      rootReducer,
      initialState,
      compose(
        applyMiddleware(sagaMiddleware),
        process.env.NODE_ENV !== 'production' && window.devToolsExtension
          ? window.devToolsExtension()
          : f => f
      )
    );
  } else {
    store = createStore(
      rootReducer,
      initialState,
      applyMiddleware(sagaMiddleware)
    );
  }
  store.sagaTask = sagaMiddleware.run(rootSaga);
  return store;
};

import React from 'react';
import { Form, Formik } from 'formik';
import { connect } from 'react-redux';
import get from 'lodash/get';
import * as PropTypes from 'prop-types';

import { RecommendationsSchema } from './schema';

import EditFormSelect from '@components/Profile/EditForm/EditFormSelect';
import { reviewSteps } from '@constants/review';
import { setData, setStep, submitForm } from '@store/user-review/actions';
import Title from '@components/shared/Title';
import Button from '@components/shared/Button';
import Error from '@components/shared/Error';
import Link from '@components/shared/Link';
import Logger from '@utils/Logger';
import Stepper from '@components/shared/Stepper';
import Helpers from '@utils/Helpers';

const AlternativesForm = ({ data, setData, submitForm, setStep, stepper }) => {
  const handleSubmit = (values, actions) => {
    actions.setSubmitting(true);
    try {
      setData({
        ...values,
        cure_effect_period: get(values, 'cure_effect_period.id')
      });
      submitForm({
        onFailed: err => {
          actions.setSubmitting(false);
          actions.setErrors(err);
        }
      });
    } catch (e) {
      actions.setSubmitting(false);
      Logger.error(e);
    }
  };

  const handleBackClick = () => {
    setStep(reviewSteps.addDescription);
  };

  return (
    <Formik
      enableReinitialize
      validateOnMount
      validationSchema={RecommendationsSchema}
      initialValues={{
        alternatives: data.alternatives
      }}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, errors }) => (
        <Form
          className="leave-review__form"
          onKeyDown={Helpers.preventKeyEvent}
        >
          <Title className="leave-review__form-title" size="md">
            Can you recommend another {get(data, 'type.entry', 'product')} that
            worked better?
          </Title>
          <Error text={errors.non_field_errors} nonField={true} />
          <EditFormSelect
            label="Recommended alternative:"
            type="select"
            name="alternatives"
            isOptional
            instanceId="combinations"
            getOptionLabel={option => option}
            getOptionValue={option => option}
            getNewOptionData={(value, label) => label}
            hideControls
            placeholder="Something that worked better for you"
          />
          <div className="leave-review__form-footer">
            <div className="row no-margin ai-middle-xxs jc-between-xxs">
              <Stepper steps={stepper.steps} current={stepper.current} />
              <div>
                <Link
                  className="leave-review__back-btn"
                  bold
                  type="button"
                  disabled={isSubmitting}
                  onClick={handleBackClick}
                >
                  Back
                </Link>
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  color="yellow"
                  loader={isSubmitting}
                >
                  Post
                </Button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

AlternativesForm.propTypes = {
  data: PropTypes.object,
  setData: PropTypes.func,
  setStep: PropTypes.func,
  submitForm: PropTypes.func,
  stepper: PropTypes.any
};

const mapStateToProps = ({ userReview: { data, stepper } }) => ({
  data,
  stepper
});

export default connect(mapStateToProps, { setData, setStep, submitForm })(
  AlternativesForm
);

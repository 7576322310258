import { handleActions } from 'redux-actions';

import types from './types';

const initialState = {
  isOpen: false,
  target: null,
  loading: false,
  reasons: []
};

const reducer = {
  [types.TOGGLE_MODAL]: (state, { payload }) => ({
    ...state,
    target: payload || null,
    isOpen: !state.isOpen
  }),
  [types.FETCH_LIST]: state => ({
    ...state,
    loading: true
  }),
  [types.FETCH_LIST_SUCCESS]: (state, { payload }) => ({
    ...state,
    loading: false,
    reasons: payload
  })
};

export const reportReducer = handleActions(reducer, initialState, {
  prefix: types.PREFIX
});

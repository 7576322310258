import React from 'react';
import { connect } from 'react-redux';
import Router from 'next/router';
import PropTypes from 'prop-types';

import { closeModal } from '@store/ui/actions';
import routes from '@constants/routes';
import modals from '@constants/modals';
import SetupProfileForm from '@components/SetupProfileForm/SetupProfileForm';
import Title from '@components/shared/Title';
import Modal from '@components/shared/Modal';

const ModalSetupProfile = ({ isOpenSetupModal, closeModal }) => (
  <Modal
    className="setup-profile-modal"
    isOpen={isOpenSetupModal}
    closeIcon={false}
  >
    <Title className="modal__title" heading="h3" size="md">
      Nice to Meet You
    </Title>
    <div className="modal__form">
      <SetupProfileForm
        successCallback={({ condition }) => {
          closeModal(modals.SETUP_MODAL);

          Router.push(
            routes.conditions.details.pattern,
            routes.conditions.details.path(condition)
          );
        }}
      />
    </div>
  </Modal>
);

ModalSetupProfile.propTypes = {
  icon: PropTypes.string,
  closeModal: PropTypes.func,
  className: PropTypes.string,
  isOpenSetupModal: PropTypes.bool
};

const mapStateToProps = ({ ui: { openModals } }) => ({
  isOpenSetupModal: openModals.includes(modals.SETUP_MODAL)
});

export default connect(mapStateToProps, { closeModal })(ModalSetupProfile);

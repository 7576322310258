export default {
  PREFIX: 'CURES',

  SET_TYPES: 'SET_TYPES',

  FETCH_CURES: 'FETCH_CURES',
  FETCH_CURES_SUCCESS: 'FETCH_CURES_SUCCESS',
  FETCH_CURES_FAILED: 'FETCH_CURES_FAILED',

  CHANGE_FILTERS: 'CHANGE_FILTERS',
  RESET_FILTERS: 'RESET_FILTERS',

  CHANGE_PAGINATION: 'CHANGE_PAGINATION',
  RESET_PAGINATION: 'RESET_PAGINATION',

  FETCH_EFFECTIVE_PERIODS: 'FETCH_EFFECTIVE_PERIODS',
  FETCH_EFFECTIVE_PERIODS_SUCCESS: 'FETCH_EFFECTIVE_PERIODS_SUCCESS'
};

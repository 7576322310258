import React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { toggleModal as toggleReviewModal } from '@store/user-review/actions';
import Header from '@components/shared/Header';
import Footer from '@components/shared/Footer';
import ModalSetupProfile from '@components/Modals/ModalSetupProfile';
import ReportModal from '@components/Modals/ModalReport';
import LeaveReviewModal from '@components/LeaveReview';
import SurveyABModal from '@components/Modals/SurveyAB';

const CommonLayout = ({ toggleReviewModal, children, props }) => {
  if (props.route.query.modal === 'leave-review') {
    toggleReviewModal();
  }

  return (
    <>
      <Header {...props} />
      <main className="main">{children}</main>
      <Footer />
      <ModalSetupProfile />
      <ReportModal />
      <LeaveReviewModal />
      <SurveyABModal />
    </>
  );
};

CommonLayout.propTypes = {
  route: PropTypes.object,
  children: PropTypes.any,
  props: PropTypes.any,
  toggleReviewModal: PropTypes.func
};

export default connect(null, { toggleReviewModal })(CommonLayout);

import { takeEvery, put, select } from 'redux-saga/effects';
import get from 'lodash/get';

import types from './types';
import { fetchDetailsSuccess } from './actions';

import API from '@utils/API';
import { apiUrls } from '@constants/api';
import Logger from '@utils/Logger';

function* asyncFetchDetails({ payload }) {
  try {
    const currentUserId = yield select(({ auth: { user } }) => get(user, 'id'));
    const url =
      currentUserId === payload.id
        ? apiUrls.profile.my
        : apiUrls.profile.details(payload.id);
    const { data } = yield API.get(url);
    yield put(fetchDetailsSuccess(data));
  } catch (err) {
    Logger.error(err);
  }
}

const withPrefix = action => `${types.PREFIX}/${action}`;

export const profileSagas = [
  takeEvery(withPrefix(types.FETCH_DETAILS), asyncFetchDetails)
];

import { takeEvery, select, put, call } from 'redux-saga/effects';

import types from './types';
import { fetchListSuccess } from './actions';

import Logger from '@utils/Logger';
import API from '@utils/API';
import { apiUrls } from '@constants/api';

function* asyncFetchList({ payload: { onSuccess, onError } }) {
  try {
    const filters = yield select(({ favorites }) => favorites.filters);
    const res = yield call(API.get, apiUrls.cures.favorites, filters);
    yield put(fetchListSuccess(res.data));
    onSuccess && onSuccess({ filters });
  } catch (err) {
    onError && onError(err);
    Logger.error(err);
  }
}
const withPrefix = action => `${types.PREFIX}/${action}`;

export const favoritesSagas = [
  takeEvery(withPrefix(types.FETCH_LIST), asyncFetchList)
];

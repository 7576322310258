const gaEvents = {
  conditionSearch: 'condition-search',
  conditionListSearch: 'condition-list-search',
  changeConditionListPage: 'condition-list-page-change',
  openReviewModal: 'open-review-modal',
  closeReviewModal: 'close-review-flow',
  filterByTag: 'filter-by-tag',
  filterByDiet: 'filter-by-diet',
  buyButtonClick: 'buy-button-click',
  relevantReviewsClick: 'relevant-reviews-click'
};

export default gaEvents;

import React from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';

const Text = ({
  element: Element = 'p',
  size,
  color,
  children,
  className,
  ...props
}) => (
  <Element
    className={classNames(
      'text',
      {
        [`text--${size}`]: size,
        [`text--${color}`]: color
      },
      className
    )}
    {...props}
  >
    {children}
  </Element>
);

Text.defaultProps = {
  size: null,
  color: null
};

Text.propTypes = {
  element: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string
};

export default Text;

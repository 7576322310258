import isFunction from 'lodash/isFunction';

import { HOTJAR_ID } from '@constants/common';

export default {
  vpv(path) {
    if (HOTJAR_ID && isFunction(window.hj)) {
      window.hj('vpv', path);
    }
  }
};

import { handleActions } from 'redux-actions';
import { clone } from 'lodash';

import types from './types';

const initialFilters = {
  disease_condition: null
};

const initialPagination = {
  page: 1,
  count: null,
  page_size: 4
};

const initialState = {
  combinationsList: [],
  combinationsErrors: {},
  combinationsLoading: false,
  filters: clone(initialFilters),
  pagination: clone(initialPagination)
};

const reducer = {
  [types.FETCH_COMBINATIONS]: state => ({
    ...state,
    combinationsLoading: true
  }),
  [types.FETCH_COMBINATIONS_SUCCESS]: (state, { payload }) => ({
    ...state,
    combinationsList: payload.results,
    combinationsLoading: false,
    pagination: {
      ...state.pagination,
      count: payload.count
    }
  }),
  [types.FETCH_COMBINATIONS_FAILED]: (state, { payload }) => ({
    ...state,
    combinationsErrors: payload,
    combinationsLoading: false
  }),
  [types.CHANGE_FILTERS]: (state, { payload }) => ({
    ...state,
    filters: {
      ...state.filters,
      ...payload
    }
  }),
  [types.RESET_FILTERS]: state => ({
    ...state,
    filters: clone(initialFilters)
  }),
  [types.CHANGE_PAGINATION]: (state, { payload }) => ({
    ...state,
    pagination: {
      ...state.pagination,
      ...payload
    }
  }),
  [types.RESET_PAGINATION]: state => ({
    ...state,
    pagination: clone(initialPagination)
  })
};

export const combinationsReducer = handleActions(reducer, initialState, {
  prefix: types.PREFIX
});

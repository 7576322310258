import { takeEvery, select, put, call } from 'redux-saga/effects';

import types from './types';
import { fetchListSuccess, markAsReviewed } from './actions';

import Logger from '@utils/Logger';
import API from '@utils/API';
import { apiUrls } from '@constants/api';
import { getShortProfile } from '@store/auth/actions';

function* asyncFetchList({ payload }) {
  try {
    const filters = yield select(({ notifications }) => notifications.filters);
    const res = yield call(API.get, apiUrls.notifications.list, filters);
    yield put(
      fetchListSuccess({
        ...res.data,
        results: res.data.results.map(i => ({
          ...i,
          is_new: i.status === 'new'
        }))
      })
    );
    yield put(markAsReviewed());
    if (payload && payload.onSuccess) {
      payload.onSuccess({ filters });
    }
  } catch (err) {
    Logger.error(err);
  }
}

function* asyncMarkAsViewed() {
  try {
    const results = yield select(({ notifications }) => notifications.results);
    const needToRead = results.filter(i => i.is_new).map(i => i.id);
    if (!needToRead.length) {
      return;
    }
    yield call(API.patch, apiUrls.notifications.markAsReviewed, needToRead);
    yield put(getShortProfile());
  } catch (err) {
    Logger.error(err);
  }
}

const withPrefix = action => `${types.PREFIX}/${action}`;

export const notificationSagas = [
  takeEvery(withPrefix(types.FETCH_LIST), asyncFetchList),
  takeEvery(withPrefix(types.MARK_AS_REVIEWED), asyncMarkAsViewed)
];

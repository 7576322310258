import React from 'react';
import Router from 'next/router';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { useGoogleLogin } from '@react-oauth/google';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

import { FACEBOOK_APP_ID } from '@constants/common';
import modals from '@constants/modals';
import routes from '@constants/routes';
import { apiUrls } from '@constants/api';
import { setUser } from '@store/auth/actions';
import { openModal } from '@store/ui/actions';
import Logger from '@utils/Logger';
import API from '@utils/API';
import Button from '@components/shared/Button';
import AuthSession from '@utils/AuthSession';

const SocialAuth = ({ query, setUser, className, customRender, openModal }) => {
  const handleSocialAuth = async (url, response) => {
    const { accessToken } = response;
    // eslint-disable-next-line no-console
    console.log(response);

    if (accessToken) {
      try {
        const {
          data: { key, user }
        } = await API.post(url, { access_token: accessToken });

        AuthSession.set(key);
        setUser(user);
        if (!user.profile_avatar) {
          openModal(modals.SETUP_MODAL);
        }
        Router.push({
          pathname: query.redirect_to || routes.home,
          query: { flow: query.flow }
        });
      } catch (err) {
        Logger.error(err);
      }
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async tokenResponse => {
      handleSocialAuth(apiUrls.auth.googleAuth, {
        accessToken: tokenResponse.access_token
      });
    },
    onError: errorResponse => Logger.log(errorResponse)
  });

  return (
    <div className={classNames('social-auth', className)}>
      <Button className="social-auth__btn" color="blue" onClick={googleLogin}>
        <img
          src="/images/google.svg"
          alt="google-logo"
          className="social-auth__logo"
        />
        Continue with Google
      </Button>

      <FacebookLogin
        appId={FACEBOOK_APP_ID}
        callback={response => {
          handleSocialAuth(apiUrls.auth.facebookAuth, response);
        }}
        disableMobileRedirect={true}
        onFailure={response => Logger.error(response)}
        render={renderProps => (
          <Button
            className="social-auth__btn"
            color="blue"
            onClick={renderProps.onClick}
            disabled={renderProps.disabled}
          >
            <img
              src="/images/facebook.svg"
              alt="facebook-logo"
              className="social-auth__logo"
            />
            Continue with Facebook
          </Button>
        )}
      />

      {customRender && customRender()}
    </div>
  );
};

SocialAuth.defaultProps = {
  query: {}
};

SocialAuth.propTypes = {
  query: PropTypes.object,
  setUser: PropTypes.func,
  className: PropTypes.string,
  customRender: PropTypes.func,
  openModal: PropTypes.func
};

export default connect(null, { setUser, openModal })(SocialAuth);
